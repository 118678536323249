import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  addPricePrediction,
  createAppraiserPropertyFields,
  getAppraiserPropertyFields,
  getPricePrediction,
} from "../api/api";
import {
  AdditionalPropertyField,
  AdditionalREType,
  Adjustments,
  AppraiserComparable,
  AppraiserPricePredictions,
  Comparable,
  ComparableFull,
  ComparableTransaction,
  FinalPricePredictions,
} from "common/types/common.types";
import CommonConfig from "common/commonConfig";
import {
  calcSoldPrice,
  comparableToAppraiserComparable,
  isComparableFull,
  recalcPredictions,
} from "../helpers/comparable.helpers";
import { useComparables, useProperty } from "./property.hooks";
import {
  AppraiserPropertyField,
  MessageType,
  SavedComparableFilter,
  StatusMessage,
} from "../types/api.types";
import { showToastError } from "common/toast/toast";
import { getErrorMessage } from "common/helpers/error.helpers";
import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { TransactionsRCContext } from "../components/TransactionsRCProvider";
import { getPredictionsWithRealPrices } from "../helpers/pricePredictions.helpers";
import { getComparableHiddenAddress } from "common/helpers/comparables.helpers";
import { Mutex } from "async-mutex";
import { getRCFiltersQuery } from "../helpers/rcTransactions.hooks";
import {
  getDefaultFilterByStage,
  getDefaultInitialFilters,
  getMonthDateRangeFromCurrentDate,
  getMonthRangeFilter,
} from "../helpers/comparableFilters.helpers";
import { useParams } from "react-router-dom";
import { AppraiserProperty } from "../types/appraiser.types";
import { useMessagesStatus } from "../components/messages/hooks";

export const pricePredictionsQueryKey = "pricePredictions";
export const pricePredictionsFinalQueryKey = "pricePredictionsFinal";

export function useFinalPricePredictions(
  propertyId: string,
  includeOtherFields?: boolean
) {
  const queryKey = [
    pricePredictionsFinalQueryKey,
    propertyId,
    includeOtherFields,
  ];
  return useQuery(queryKey, () =>
    getPricePrediction<FinalPricePredictions>(
      propertyId,
      true,
      includeOtherFields
    )
  );
}

export function useAppraiserPropertyFields() {
  const { data: appraiserPropertyFields } = useQuery(
    "appraiserPropertyFields",
    getAppraiserPropertyFields
  );
  const getAppraiserPropertyFieldName = (id: string) =>
    appraiserPropertyFields?.find((field) => field._id === id)?.name;
  return { appraiserPropertyFields, getAppraiserPropertyFieldName };
}

export function useAppraiserPropertyFieldsMutation() {
  const queryClient = useQueryClient();

  return useMutation(createAppraiserPropertyFields, {
    onSuccess: (propertyField) => {
      queryClient.setQueryData<AppraiserPropertyField[]>(
        "appraiserPropertyFields",
        (data) => {
          return [...(data ?? []), propertyField];
        }
      );
    },
    onError: (error) => {
      showToastError(getErrorMessage(error));
    },
  });
}

const mutexForComparableCart = new Mutex();

export function usePricePredictions(propertyId: string, readonly?: boolean) {
  const queryClient = useQueryClient();

  const queryKey = [pricePredictionsQueryKey, propertyId];
  const {
    data,
    isLoading: isLoadingPricePredictions,
    refetch,
  } = useQuery(
    queryKey,
    () => getPricePrediction<AppraiserPricePredictions>(propertyId),
    {
      staleTime: 1000 * 60,
      enabled: !readonly,
    }
  );

  const { buyTransaction } = useContext(TransactionsRCContext);
  const { dataFull: comparablesFull } = useComparables();

  const findAppraiserComparable = (comparableId: string) => {
    return [
      ...(data?.comparables ?? []),
      ...(data?.comparables_adjusted ?? []),
    ].find(
      (comp) =>
        comp.comparable_transaction.id.toString() === comparableId.toString()
    );
  };

  const findComparableFull = (comparableId: string) => {
    return comparablesFull?.find(
      (comp) =>
        comp.comparable_transaction.id.toString() === comparableId.toString()
    );
  };

  const getComparableAdjustments = (comparable: Comparable) => {
    return (
      findAppraiserComparable(comparable.comparable_transaction.id)
        ?.adjustments ?? comparable.adjustments
    );
  };

  const getComparablesInCartIds = useCallback(() => {
    return data?.comparables.map((comp) =>
      comp.comparable_transaction.id.toString()
    );
  }, [data?.comparables]);

  const getComparableIndex = useCallback(
    (comp: Comparable) => {
      return (
        getComparablesInCartIds()?.findIndex(
          (x) => x == comp.comparable_transaction.id.toString()
        ) ?? 0
      );
    },
    [getComparablesInCartIds]
  );

  const isComparableInCart = useCallback(
    (comparableId: string) => {
      return getComparablesInCartIds()?.includes(comparableId.toString());
    },
    [getComparablesInCartIds]
  );

  const { mutate, mutateAsync, isLoading } = useMutation(
    (pricePrediction: AppraiserPricePredictions) =>
      addPricePrediction(propertyId, pricePrediction),
    {
      onMutate: async (pricePrediction) => {
        await queryClient.cancelQueries(queryKey);
        const previousPredictions =
          queryClient.getQueryData<AppraiserPricePredictions>(queryKey);
        queryClient.setQueryData(queryKey, pricePrediction);
        return { previousPredictions };
      },
      onError: (error, _, context) => {
        CommonConfig.errorHandler(error);
        if (context?.previousPredictions) {
          queryClient.setQueryData(queryKey, context.previousPredictions);
        }
      },
      onSuccess: () => {
        queryClient.invalidateQueries("compositeAssets");
      },
    }
  );

  const removeComparableFromCart = (compId: string) => {
    if (!data) return;

    const comparablesAdjusted = [...data.comparables_adjusted];
    const adjustedComparable = findAppraiserComparable(compId);
    if (adjustedComparable?.adjustments?.is_corrected) {
      comparablesAdjusted.push(adjustedComparable);
    }

    const newData = {
      ...data,
      comparables: data.comparables.filter(
        (c) => c.comparable_transaction.id.toString() !== compId.toString()
      ),
      comparables_adjusted: comparablesAdjusted,
    };

    mutate(recalcPredictions(newData));
  };

  const addComparableToCart = async (comparable: Comparable) => {
    if (!data || !buyTransaction) return;

    const boughtComparable = isComparableFull(comparable)
      ? comparable
      : await buyTransaction(comparable.comparable_transaction.id, propertyId);
    try {
      await mutexForComparableCart.acquire();

      const latestData = queryClient.getQueryData<
        AppraiserPricePredictions & { createdAt: string; updatedAt: string }
      >(queryKey);

      if (!latestData) {
        return;
      }

      const newData = {
        ...latestData,
        comparables: [
          ...latestData.comparables,
          comparableToAppraiserComparable(boughtComparable),
        ],
        comparables_adjusted: latestData.comparables_adjusted.filter(
          (c) =>
            c.comparable_transaction.id.toString() !==
            boughtComparable.comparable_transaction.id.toString()
        ),
      };
      await mutateAsync(recalcPredictions(newData));
    } catch (error) {
      throw error;
    } finally {
      mutexForComparableCart.release();
    }
  };

  const updateComparableAdjustments = async (
    comparableId: string,
    adjustments: Adjustments,
    additionalPropertyFields?: AdditionalPropertyField[]
  ) => {
    let comparable = findAppraiserComparable(comparableId);
    if (!comparable) {
      const comp = findComparableFull(comparableId);
      if (!!comp) {
        comparable = comparableToAppraiserComparable(comp);
        data?.comparables_adjusted.push(comparable);
      }
    }
    if (!!comparable && !!data) {
      if (!comparable.adjustments.is_corrected) {
        comparable.adjustments_original = comparable.adjustments;
      }
      comparable.adjustments = { ...adjustments, is_corrected: true };

      data.additional_property_fields =
        additionalPropertyFields ?? data.additional_property_fields;
      await mutateAsync(recalcPredictions(data));
    }
  };

  const updateWeights = async (weights: number[]) => {
    if (!data) return;
    data.comparables.forEach((comp, index) => {
      comp.comparable_weight = weights[index];
    });
    await mutateAsync(recalcPredictions(data));
  };

  const updateAdjustmentDescription = async (
    field: string,
    description: string
  ) => {
    if (!data) return;
    data.descriptions =
      data.descriptions?.filter((d) => d.field !== field) ?? [];
    data.descriptions?.push({ field, description });

    // Needs recalc because it is updated simultaneously with adjustments
    await mutateAsync(recalcPredictions(data));
  };

  const updateAdditionalAdjustmentDescription = async (
    appraiserPropertyFieldId: string,
    description: string
  ) => {
    if (!data) return;
    data.descriptions = data.descriptions?.filter(
      (d) => d.appraiserPropertyFieldId !== appraiserPropertyFieldId
    );
    data.descriptions?.push({ appraiserPropertyFieldId, description });
    await mutateAsync(data);
  };

  const getFinalPricePredictions = (): FinalPricePredictions | undefined => {
    if (!data || !comparablesFull) {
      return undefined;
    }

    const finalPricePredictions: FinalPricePredictions = {
      predicted_price: data.predicted_price,
      comparables: data.comparables
        .map((comparable) => ({
          ...(comparablesFull.find(
            (comp) =>
              comparable.comparable_transaction.id.toString() ===
              comp.comparable_transaction.id.toString()
          ) as ComparableFull),
          adjustments_original: comparable.adjustments_original,
          adjustments: comparable.adjustments,
          comparable_weight: comparable.comparable_weight,
        }))
        .map((comparable) => ({
          ...comparable,
          comparable_transaction: {
            ...comparable.comparable_transaction,
            address: getComparableHiddenAddress(
              comparable.comparable_transaction
            ),
          },
        })),
      additional_property_fields: data.additional_property_fields?.filter(
        (field) =>
          data.comparables.some((comp) =>
            comp.adjustments.additional_fields?.some(
              (additional) =>
                additional.appraiserPropertyFieldId ===
                field.appraiserPropertyFieldId
            )
          )
      ),
    };
    return finalPricePredictions;
  };

  return {
    isComparableInCart,
    getComparablesInCartIds,
    getComparableIndex,
    removeComparableFromCart,
    addComparableToCart,
    getComparableAdjustments,
    updateComparableAdjustments,
    getFinalPricePredictions,
    updateWeights,
    isLoading,
    isLoadingPricePredictions,
    pricePredictions: data,
    updateAdjustmentDescription,
    updateAdditionalAdjustmentDescription,
    refetch,
  };
}

export function useComparablesSummary(propertyId: string) {
  const { getFinalPricePredictions } = usePricePredictions(propertyId);
  const finalPricePredictions = useMemo(
    () => getFinalPricePredictions(),
    [getFinalPricePredictions]
  );

  const comps = getPredictionsWithRealPrices(finalPricePredictions);

  return { comps };
}

export const MAX_TRANSACTIONS = 10;
export const MAX_PERCENT_DIFF = 0.2;

export const useAutoPredictPrice = () => {
  const { refetch, buyTransactions } = useContext(TransactionsRCContext);

  const [isLoading, setIsLoading] = useState(false);

  const queryClient = useQueryClient();

  const getComparablesByStage = async (
    stage: number,
    property: AppraiserProperty,
    compsStatus: { [key: string]: StatusMessage },
    filterOutRelatedRE = true
  ) => {
    const defaultFilters = getDefaultFilterByStage(property, stage);
    const rcFiltersQuery = getRCFiltersQuery([...defaultFilters]);

    const transactions = await refetch?.(
      rcFiltersQuery,
      property._id,
      false,
      true,
      true
    );

    if (!transactions) {
      throw new Error("Įvyko klaida ieškant sandorių");
    }

    const hasRelatedREOfType = (
      comparable: ComparableTransaction,
      reType: AdditionalREType
    ) => {
      return comparable.related_re.some((re) => re.type === reType);
    };

    let allTransactions = [
      ...transactions.base_transactions,
      ...transactions.full_transactions,
    ];

    allTransactions.sort((a, b) => {
      return b.similarities.joint_similarity - a.similarities.joint_similarity;
    });

    if (filterOutRelatedRE) {
      allTransactions = allTransactions.filter(
        (t) =>
          !hasRelatedREOfType(
            t.comparable_transaction,
            AdditionalREType.Basement
          ) &&
          !hasRelatedREOfType(t.comparable_transaction, AdditionalREType.Garage)
      );
    }

    // filter out fake transactions
    allTransactions = allTransactions.filter(
      (t) =>
        compsStatus[t.comparable_transaction.id]?.type !== MessageType.FAKED
    );

    const transactionIdToSkip = property.transactionId;

    const selectedTransactions = allTransactions.filter(
      (t) => t.comparable_transaction.id !== transactionIdToSkip
    );

    return { defaultFilters, selectedTransactions };
  };

  const getPercentDiff = (
    transactions: ComparableFull[],
    slidingWindow = 3
  ) => {
    const sortedTransactions = transactions.sort(
      (a, b) =>
        a.comparable_transaction.sold_price_area -
        b.comparable_transaction.sold_price_area
    );

    let bestPercentDiff = 1000000;
    let bestTransactions: ComparableFull[] = [];

    for (let i = 0; i <= sortedTransactions.length - slidingWindow; i++) {
      const minPrice =
        sortedTransactions[i].comparable_transaction.sold_price_area;
      const maxPrice =
        sortedTransactions[i + slidingWindow - 1].comparable_transaction
          .sold_price_area;

      const percentDiff = maxPrice / minPrice - 1;
      if (percentDiff < bestPercentDiff) {
        bestPercentDiff = percentDiff;
        bestTransactions = sortedTransactions.slice(i, i + slidingWindow);
      }
    }

    return {
      percentDiff: bestPercentDiff,
      transactions: bestTransactions,
    };
  };

  const buySelectedTransactions = async (
    selectedTransactions: Comparable[],
    property: AppraiserProperty,
    count: number
  ): Promise<ComparableFull[]> => {
    const transactionsNeeded = selectedTransactions.splice(0, count);

    const transactionsToBuy = transactionsNeeded
      .filter((tr) => !isComparableFull(tr))
      .map((tr) => tr.comparable_transaction.id);

    const boughtTransactions = await buyTransactions?.(
      transactionsToBuy,
      property._id
    );

    if (!boughtTransactions) {
      throw new Error("Įvyko klaida perkant sandorius");
    }

    return [
      ...boughtTransactions,
      ...transactionsNeeded.filter((tr) => isComparableFull(tr)),
    ] as ComparableFull[];
  };

  const getFullTransactions = async (
    selectedTransactions: Comparable[],
    property: AppraiserProperty,
    avoidOutliers = false,
    maxTransactions = MAX_TRANSACTIONS
  ): Promise<ComparableFull[]> => {
    const fullTransactions = await buySelectedTransactions(
      selectedTransactions,
      property,
      3
    );

    if (!avoidOutliers) {
      return fullTransactions;
    }
    let {
      transactions: transactionsWithBestDiff,
      percentDiff: bestPercentDiff,
    } = getPercentDiff(fullTransactions);

    while (
      bestPercentDiff > MAX_PERCENT_DIFF &&
      fullTransactions.length < maxTransactions &&
      selectedTransactions.length > 0
    ) {
      const additionalTransactions = await buySelectedTransactions(
        selectedTransactions,
        property,
        1
      );
      fullTransactions.push(...additionalTransactions);
      const res = getPercentDiff(fullTransactions);
      if (res.percentDiff < bestPercentDiff) {
        bestPercentDiff = res.percentDiff;
        transactionsWithBestDiff = res.transactions;
      }
    }

    return transactionsWithBestDiff;
  };

  const { refetch: refetchMessagesStatus } = useMessagesStatus();

  const autoPredictPrice = async (
    property: AppraiserProperty,
    filterOutRelatedRE = true,
    avoidOutliers = false,
    maxTransactions = MAX_TRANSACTIONS
  ) => {
    setIsLoading(true);
    try {
      const { data: compsStatus } = await refetchMessagesStatus();

      if (!compsStatus) {
        throw new Error("Įvyko klaida gaunant pranešimų statusą");
      }

      let selectedTransactions: Comparable[] = [];
      let defaultFilters: SavedComparableFilter[] = [];

      for (let stage = 0; stage < 3; stage++) {
        let res = await getComparablesByStage(
          stage,
          property,
          compsStatus,
          filterOutRelatedRE
        );
        selectedTransactions = res.selectedTransactions;
        defaultFilters = res.defaultFilters;

        if (selectedTransactions.length >= 3) {
          break;
        }
      }

      if (selectedTransactions.length < 3) {
        showToastError("Nepavyko rasti pakankamai sandorių");
        return;
      }

      const selectedComps = await getFullTransactions(
        selectedTransactions,
        property,
        avoidOutliers,
        maxTransactions
      );

      selectedComps.sort((a, b) => {
        return (
          b.similarities.joint_similarity - a.similarities.joint_similarity
        );
      });

      const pricePredictions = recalcPredictions({
        predicted_price: { min: 0, max: 0, average_price_area: 0 },

        comparables: selectedComps as AppraiserComparable[],
        comparables_adjusted: [],
        applied_filters: defaultFilters,
      });

      await addPricePrediction(property._id, pricePredictions, false);

      await queryClient.invalidateQueries([
        pricePredictionsQueryKey,
        property._id,
      ]);

      return pricePredictions;
    } catch (error) {
      showToastError(getErrorMessage(error));
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return { autoPredictPrice, isLoading };
};
