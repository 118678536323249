import { Button, Dialog, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { useMutation } from "react-query";
import {
  generatePropertyShareLink,
  setLeadContactPermissions,
} from "../../api/api";
import ShareIcon from "@mui/icons-material/Share";
import { DialogHeader } from "../../components/dialogs/DialogHeader";
import { useProperty } from "../../hooks/property.hooks";
import { PropertyNewLinkShareSettings } from "./PropertyNewLinkShareSettings";

interface PropertyShareButtonProps {
  propertyId: string;
}

export const PropertyShareButton: React.FC<PropertyShareButtonProps> = ({
  propertyId,
}) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const { data: property } = useProperty(propertyId);

  if (!property) {
    return null;
  }

  return (
    <>
      <Button
        variant="outlined"
        onClick={async () => {
          setOpen(true);
        }}
        style={{ alignSelf: "flex-end" }}
        startIcon={<ShareIcon />}
      >
        Bendrinti
      </Button>
      <Dialog open={open} onClose={handleClose} scroll="body">
        <DialogHeader title="Bendrinti" onClose={handleClose} />
        <PropertyNewLinkShareSettings propertyId={property._id} />
      </Dialog>
    </>
  );
};
