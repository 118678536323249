import { CheckboxButtonGroup } from "common/components/form/CheckboxButtonGroup";
import React, { useEffect, useState } from "react";
import { MUILoadingButton } from "../../components/buttons/MUILoadingButton";
import { showToastError } from "common/toast/toast";
import { LinkBox } from "../../components/links/LinkBox";
import { getPropertyValueString } from "../../helpers/property.helpers";
import { AppraiserProperty } from "../../types/appraiser.types";

interface PropertyShareSettingsProps {
  property?: AppraiserProperty;
  onChange?: (permissions: PropertyPermission[]) => void;
  initialPermissions?: PropertyPermission[];
  purchasedPermissions?: PropertyPermission[];
  disablePermissions?: boolean;
  children?: React.ReactNode;
}

export enum PropertyPermission {
  Edit = "edit",
  ViewAppraiser = "viewAppraiser",
  ViewGallery = "viewGallery",
  ViewAppraiserComments = "viewAppraiserComments",
  ViewProperty = "viewInfo",
  ViewValue = "viewValue",
  ViewComparables = "viewComparables",
  ViewCompositeAssets = "viewCompositeAssets",
  ViewCalculations = "viewCalculations",
  ViewDocuments = "viewDocuments",
  ViewDistances = "viewDistances",
  ViewMarketInsights = "viewMarketInsights",
  ViewMapsAndClimateRisks = "viewMapsAndClimateRisks",
  Legal = "legal",
}

export const basicPermissions = [
  PropertyPermission.ViewAppraiser,
  PropertyPermission.ViewProperty,
  PropertyPermission.ViewValue,
  PropertyPermission.Legal,
  PropertyPermission.ViewDocuments,
  PropertyPermission.ViewGallery,
  PropertyPermission.ViewAppraiserComments,
];

export const PropertyShareSettings: React.FC<PropertyShareSettingsProps> = ({
  property,
  initialPermissions,
  purchasedPermissions,
  disablePermissions,
  onChange,
  children,
}) => {
  const selectValues = [
    {
      label: "Vertintojo informacija",
      value: PropertyPermission.ViewAppraiser,
    },
    {
      label: "Apie galimos kainos nustatymą",
      value: PropertyPermission.Legal,
    },
    {
      label: getPropertyValueString(property),
      value: PropertyPermission.ViewValue,
    },
    {
      label: "Informacija apie turtą",
      value: PropertyPermission.ViewProperty,
    },
    {
      label: "Galerija",
      value: PropertyPermission.ViewGallery,
    },
    {
      label: "Skaičiavimų pagrindimas",
      value: PropertyPermission.ViewCalculations,
    },
    {
      label: "Dokumentai",
      value: PropertyPermission.ViewDocuments,
    },
    {
      label: "Vertintojo komentaras",
      value: PropertyPermission.ViewAppraiserComments,
    },
    {
      label: "Atstumai",
      value: PropertyPermission.ViewDistances,
    },
    {
      label: "Žemėlapiai ir klimato rizikos",
      value: PropertyPermission.ViewMapsAndClimateRisks,
    },
    {
      label: "Rinkos apžvalga",
      value: PropertyPermission.ViewMarketInsights,
    },
  ];

  const settings = selectValues.map((v) => ({
    ...v,
    label: purchasedPermissions?.includes(v.value)
      ? `${v.label} (Įsigyta)`
      : v.label,
  }));

  const basicSettings = settings.filter((s) =>
    basicPermissions.includes(s.value)
  );
  const otherSettings = settings.filter(
    (s) => !basicPermissions.includes(s.value)
  );

  const disabledPermissions = [
    ...(purchasedPermissions?.filter((p) => !basicPermissions.includes(p)) ??
      []),
    ...basicPermissions,
  ];

  const [selectedPermissions, setSelectedPermissions] = useState<
    PropertyPermission[]
  >(initialPermissions ?? []);

  return (
    <div style={{ width: "500px", padding: "20px" }}>
      <CheckboxButtonGroup
        values={[
          {
            label: "Pasirinkti visus",
            value: undefined,
            children: [
              {
                label: "Pagrindinė informacija",
                value: undefined,
                children: basicSettings,
              },
              ...otherSettings,
            ],
          },
        ]}
        disabledValues={disablePermissions ? disabledPermissions : []}
        selectedValues={selectedPermissions}
        onSelect={(perms) => {
          const newPermissions = [...selectedPermissions, ...perms];
          setSelectedPermissions(newPermissions);
          onChange?.(newPermissions);
        }}
        onUnselect={(perms) => {
          const newPermissions = selectedPermissions.filter(
            (p) => !perms.includes(p)
          );
          setSelectedPermissions(newPermissions);
          onChange?.(newPermissions);
        }}
        backgroundColor="white"
      />
      {children}
    </div>
  );
};
