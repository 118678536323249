import React from "react";
import { TransactionsRCProvider } from "./TransactionsRCProvider";
import { useParams } from "react-router-dom";
import { ComparablesFilterProvider } from "./ComparablesFilterProvider";

interface TransactionsRCProviderWithResetProps {
  children?: React.ReactNode;
}

export const TransactionsRCProviderWithReset: React.FC<
  TransactionsRCProviderWithResetProps
> = ({ children }) => {
  const { propertyId } = useParams();

  const key = propertyId ?? "none";

  return (
    <ComparablesFilterProvider key={key}>
      <TransactionsRCProvider key={key}>{children}</TransactionsRCProvider>
    </ComparablesFilterProvider>
  );
};
