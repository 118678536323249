import React from "react";
import { Typography } from "common/components/Typography";
import { IconBadge } from "common/components/map/IconBadge";
import WbSunnyOutlinedIcon from "@mui/icons-material/WbSunnyOutlined";
import colors from "common/styles/colors";
import { PropertyHeatRisk } from "../../types/appraiser.types";
import { ClimateRiskTypesTranslations } from "../../helpers/comparableFields.helpers";
import {
  determineBackgroundColor,
  getHeatRiskDescription,
} from "../../helpers/climateRisk.helpers";
import { PropertyClimateRiskWrapper } from "./PropertyClimateRiskWrapper";

interface PropertyHeatRiskContentProps {
  heatRisk?: PropertyHeatRisk;
}

export const PropertyHeatRiskContent: React.FC<
  PropertyHeatRiskContentProps
> = ({ heatRisk }) => {
  return (
    <PropertyClimateRiskWrapper
      title={<Typography variant="h5">Karščio rizika</Typography>}
      description={
        <Typography variant="caption">
          Nustatoma, kai temperatūra viršija 30 °C.
        </Typography>
      }
      icon={
        <IconBadge
          width={40}
          height={40}
          backgroundColor={
            heatRisk?.risk
              ? determineBackgroundColor(heatRisk.risk)
              : colors.grey
          }
        >
          <WbSunnyOutlinedIcon fontSize="medium" style={{ color: "white" }} />
        </IconBadge>
      }
      riskType={
        <Typography variant="h4">
          {heatRisk?.risk
            ? ClimateRiskTypesTranslations[heatRisk.risk]
            : "Nenustatyta"}
        </Typography>
      }
      riskDescription={
        <Typography variant="caption">
          {heatRisk?.risk
            ? getHeatRiskDescription(heatRisk.hotDaysCount)
            : "Karščio rizika nenustatyta."}
        </Typography>
      }
    />
  );
};
