import {
  Comparable,
  ComparableFull,
  ComparableTransaction,
  ComparableTransactionFull,
} from "common/types/common.types";
import { MonthDate } from "../components/datepicker/types";
import _ from "lodash";
import { AppraiserProperty } from "../types/appraiser.types";
import { isCompMatched } from "common/helpers/comparables.helpers";
import {
  DesignationTypeTranslations,
  FloorType,
  WallType,
} from "./comparableFields.helpers";
import { isComparableFull } from "./comparable.helpers";
import { SavedComparableFilter } from "../types/api.types";
import { DesignationType } from "common/types/comparableFields.types";
import {
  maxFinishingFull,
  minFinishingFull,
} from "common/components/form/MyPropertyDetailsEditForm";

export interface ComparableFilter extends SavedComparableFilter {
  filterFunction: (comp: Comparable) => boolean;
}

export enum ComparableFilterType {
  Area = "Area",
  Year = "Year",
  Bounds = "Bounds",
  Floor = "Floor",
  Dates = "Dates",
  Polygon = "Polygon",
  Finishing = "FinishingFull",
  MatchedTransactions = "MatchedTransactions",
  Walls = "Walls",
  EnergyClass = "EnergyClass",
  Designation = "Designation",
  ValuationZone = "ValuationZone",
  PriceArea = "PriceArea",
}

export const comparableFilterTypeToLabel: {
  [K in ComparableFilterType]?: string | undefined;
} = {
  [ComparableFilterType.Area]: "Plotas (m2)",
  [ComparableFilterType.Year]: "Statybos metai",
  [ComparableFilterType.Floor]: "Aukštas",
  [ComparableFilterType.Dates]: "Sandorio laikotarpis",
  [ComparableFilterType.Walls]: "Konstruktyvas",
  [ComparableFilterType.Designation]: "Paskirtis",
  [ComparableFilterType.ValuationZone]: "Vertinimo zona",
};

export const getFilterValues = (filter: SavedComparableFilter) => {
  if (
    filter.type === ComparableFilterType.Area ||
    filter.type === ComparableFilterType.Year
  ) {
    return filter.values.join(" - ");
  }
  if (filter.type === ComparableFilterType.Dates) {
    return `${filter.values.start.year}-${filter.values.start.month} - ${filter.values.end.year}-${filter.values.end.month}`;
  }

  if (filter.type === ComparableFilterType.ValuationZone) {
    return filter.values.join(", ");
  }
  if (filter.type === ComparableFilterType.Designation) {
    return filter.values
      .map((v: DesignationType) => DesignationTypeTranslations[v])
      .join(", ");
  }
};

export const otherFilters = [
  ComparableFilterType.Bounds,
  ComparableFilterType.Polygon,
];

export const extendedFilters = [
  ComparableFilterType.Floor,
  ComparableFilterType.Walls,
  ComparableFilterType.Finishing,
  ComparableFilterType.EnergyClass,
  ComparableFilterType.PriceArea,
];

export const DEFAULT_AREA_FILTER_PERCENT_RANGE = 20;
export const DEFAULT_AREA_FILTER_MIN_RANGE = 10;
export const DEFAULT_YEAR_FILTER_RANGE = 10;

export const getDefaultAreaValues = (property: AppraiserProperty) => {
  let percentRange = Math.ceil(
    (property.area * DEFAULT_AREA_FILTER_PERCENT_RANGE) / 100
  );
  percentRange = Math.max(DEFAULT_AREA_FILTER_MIN_RANGE, percentRange);

  const minArea = Math.max(0, property.area - percentRange);
  const maxArea = property.area + percentRange;
  return [Math.round(minArea), Math.round(maxArea)];
};

export const getDefaultYearValues = (property: AppraiserProperty) => {
  const currentYear = new Date().getFullYear();

  const minYear = null;
  let maxYear: number | null = property.year + DEFAULT_YEAR_FILTER_RANGE;
  if (maxYear > currentYear) {
    maxYear = null;
  }

  return [minYear, maxYear];
};

export const getDefaultValuationZoneFilter = (property: AppraiserProperty) => {
  if (!!property.valuationZone) {
    return [property.valuationZone];
  }
  return [];
};

export const getDefaultDesignationFilter = (property: AppraiserProperty) => {
  return [property.designationType ?? DesignationType.ResidentialApartments];
};

export const getDefaultDatesFilter = (property: AppraiserProperty) => {
  const defaultMonthRange = getMonthDateRangeFromCurrentDate(
    12,
    !!property.soldDate ? new Date(property.soldDate) : new Date()
  );
  return {
    start: defaultMonthRange.startDate,
    end: defaultMonthRange.endDate,
  };
};

export const getDefaultInitialFilters = (property: AppraiserProperty) => {
  const initialFilters: SavedComparableFilter[] = [
    {
      type: ComparableFilterType.Area,
      values: getDefaultAreaValues(property),
    },
    {
      type: ComparableFilterType.Year,
      values: getDefaultYearValues(property),
    },
    {
      type: ComparableFilterType.ValuationZone,
      values: getDefaultValuationZoneFilter(property),
    },
    {
      type: ComparableFilterType.Designation,
      values: getDefaultDesignationFilter(property),
    },
    {
      type: ComparableFilterType.Dates,
      values: getDefaultDatesFilter(property),
    },
  ];

  return initialFilters;
};

export const getDefaultFilterByStage = (
  property: AppraiserProperty,
  stage: number = 0
) => {
  let defaultFilters = getDefaultInitialFilters(property);

  const updateFilter = (filter: SavedComparableFilter) => {
    defaultFilters = [
      ...defaultFilters.filter((f) => f.type !== filter.type),
      filter,
    ];
    return defaultFilters;
  };

  const getExpandedDateFilter = () => {
    const defaultMonthRange = getMonthDateRangeFromCurrentDate(
      36,
      !!property.soldDate ? new Date(property.soldDate) : new Date(),
      new Date(property.soldDate ?? new Date()) < new Date(2022, 9)
        ? undefined
        : {
            year: 2022,
            month: 9,
          }
    );
    return {
      start: defaultMonthRange.startDate,
      end: defaultMonthRange.endDate,
    };
  };

  if (stage >= 1) {
    updateFilter({
      type: ComparableFilterType.Dates,
      values: getExpandedDateFilter(),
    });
  }

  if (stage >= 2) {
    defaultFilters = defaultFilters.filter(
      (filter) =>
        ![ComparableFilterType.Year, ComparableFilterType.Area].includes(
          filter.type
        )
    );
  }

  return defaultFilters;
};

export const isInBounds = (
  comparable: Comparable,
  bounds: google.maps.LatLngBounds
) => {
  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();

  return (
    comparable.comparable_transaction.location.lat <= ne.lat() &&
    comparable.comparable_transaction.location.lat >= sw.lat() &&
    comparable.comparable_transaction.location.lng <= ne.lng() &&
    comparable.comparable_transaction.location.lng >= sw.lng()
  );
};

export function groupComparablesByCoordinates(
  objects: Comparable[]
): Comparable[][] {
  const grouped: Comparable[][] = [];

  for (const obj of objects) {
    const { lat, lng } = obj.comparable_transaction.location;

    // Find an existing group with the same coordinates
    const existingGroup = grouped.find(
      (group) =>
        group[0].comparable_transaction.location.lat === lat &&
        group[0].comparable_transaction.location.lng === lng
    );

    if (existingGroup) {
      existingGroup.push(obj);
    } else {
      // Create a new group if no existing group found
      grouped.push([obj]);
    }
  }

  return grouped;
}

export function filterRange<T extends keyof ComparableTransactionFull>(
  comparable: Comparable,
  field: T,
  min: ComparableTransactionFull[T] | undefined,
  max: ComparableTransactionFull[T] | undefined
) {
  let minValue, maxValue;
  if (min === null || min === undefined) {
    minValue = Number.MIN_VALUE;
  } else {
    minValue = min;
  }

  if (max === null || max === undefined) {
    maxValue = Number.MAX_VALUE;
  } else {
    maxValue = max;
  }

  // @ts-ignore
  const fieldValue = comparable.comparable_transaction[field];
  if (fieldValue === null || fieldValue === undefined) {
    return false;
  }

  return fieldValue >= minValue && fieldValue <= maxValue;
}

const filterConstructionYear = (
  comparable: Comparable,
  min: number | undefined,
  max: number | undefined
) => {
  return filterRange(comparable, "construction_year", min, max);
};

export function getMonthDateRangeFromCurrentDate(
  monthLength: number = 12,
  currentDate: Date = new Date(),
  minimumAllowedDate: MonthDate | null = null
): { startDate: MonthDate; endDate: MonthDate } {
  const startDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - monthLength + 1,
    1
  );

  if (minimumAllowedDate) {
    const minDate = new Date(
      minimumAllowedDate.year,
      minimumAllowedDate.month - 1,
      1
    );

    if (startDate < minDate) {
      startDate.setFullYear(minDate.getFullYear());
      startDate.setMonth(minDate.getMonth());
    }
  }

  return {
    startDate: {
      year: startDate.getFullYear(),
      month: startDate.getMonth() + 1,
    },
    endDate: {
      year: currentDate.getFullYear(),
      month: currentDate.getMonth() + 1,
    },
  };
}

function getMonthRangeFilterFunction(start: MonthDate, end: MonthDate) {
  return (comp: Comparable) => {
    const startDate = new Date(start.year, start.month - 1, 1);
    const endDate = new Date(end.year, end.month, 1);

    const soldDate = new Date(`${comp.comparable_transaction.sold_date}-01`);

    return soldDate >= startDate && soldDate < endDate;
  };
}

export function getMonthRangeFilter(start: MonthDate, end: MonthDate) {
  return {
    type: ComparableFilterType.Dates,
    values: { start, end },
    filterFunction: getMonthRangeFilterFunction(start, end),
  };
}

function getDefaultFilters(): ComparableFilter[] {
  const defaultMonthRange = getMonthDateRangeFromCurrentDate();

  return [
    {
      type: ComparableFilterType.Floor,
      filterFunction: () => true,
      values: FloorType.All,
    },
    getMonthRangeFilter(defaultMonthRange.startDate, defaultMonthRange.endDate),
    {
      type: ComparableFilterType.Finishing,
      filterFunction: () => true,
      values: [minFinishingFull, maxFinishingFull],
    },
  ];
}

export const defaultFilters = getDefaultFilters();

function isCoordinatesInPolygon(
  lat: number,
  lng: number,
  polygon: google.maps.Polygon
) {
  let numIntersections = 0;
  const vertices = polygon.getPath().getArray();

  for (let i = 0, j = vertices.length - 1; i < vertices.length; j = i++) {
    const vertexI = vertices[i];
    const vertexJ = vertices[j];
    const latI = vertexI.lat();
    const lngI = vertexI.lng();
    const latJ = vertexJ.lat();
    const lngJ = vertexJ.lng();

    if (
      latI > lat !== latJ > lat &&
      lng < ((lngJ - lngI) * (lat - latI)) / (latJ - latI) + lngI
    ) {
      numIntersections++;
    }
  }

  return numIntersections % 2 !== 0;
}

export function isComparableInPolygon(
  comp: Comparable,
  polygon: google.maps.Polygon
) {
  return isCoordinatesInPolygon(
    comp.comparable_transaction.location.lat,
    comp.comparable_transaction.location.lng,
    polygon
  );
}

export const isOtherOrDefaultFilter = (filter: ComparableFilter) => {
  return (
    otherFilters.some((other) => other === filter.type) ||
    defaultFilters.some(
      (def) => def.type === filter.type && _.isEqual(def.values, filter.values)
    )
  );
};

export const getModalFilters = (filters: ComparableFilter[]) => {
  return filters.filter((filter) => !isOtherOrDefaultFilter(filter));
};

export const getFloorFilterFunction = (floor: number) => {
  return (comp: Comparable) => {
    if (floor === FloorType.All) return true;
    if (!isComparableFull(comp)) return false;
    const floorPosition = comp.comparable_transaction.floor_position;
    if (!floorPosition) {
      return true;
    }
    return floorPosition === floor;
  };
};

export const getFinishingFilterFunction = (finishing: number[]) => {
  return (comp: Comparable) => {
    if (
      finishing[0] === minFinishingFull &&
      finishing[1] === maxFinishingFull
    ) {
      return true;
    }
    if (!isComparableFull(comp)) return false;
    const fullFinishing = comp.comparable_transaction.finishing_full;
    return fullFinishing >= finishing[0] && fullFinishing <= finishing[1];
  };
};

export const getPriceAreaFilterFunction = (priceArea: number[]) => {
  return (comp: Comparable) => {
    if (!isComparableFull(comp)) return false;
    const soldPriceArea = comp.comparable_transaction.sold_price_area;
    return soldPriceArea >= priceArea[0] && soldPriceArea <= priceArea[1];
  };
};

export const getPolygonFilterFunction = (polygons: google.maps.Polygon[]) => {
  return (comp: Comparable) =>
    polygons.some((poly) => isComparableInPolygon(comp, poly));
};

export const getBoundsFilterFunction = (bounds: google.maps.LatLngBounds) => {
  return (comp: Comparable) => {
    return bounds.contains({
      lat: comp.comparable_transaction.location.lat,
      lng: comp.comparable_transaction.location.lng,
    });
  };
};

export const getMatchingTransactionFilterFunction = (
  onlyMatched?: boolean | null
) => {
  return (comp: Comparable) => {
    if (!onlyMatched) {
      return true;
    }
    return isCompMatched(comp.comparable_transaction);
  };
};

export const getWallsTypeFilterFunction = (selectedWalls: WallType[]) => {
  return (comp: Comparable) => {
    if (!selectedWalls.length) {
      return true;
    }
    if (!isComparableFull(comp)) {
      return false;
    }
    return selectedWalls.includes(comp.comparable_transaction.walls);
  };
};

export const getEnergyClassFilterFunction = (selectedClasses: string[]) => {
  return (comp: Comparable) => {
    if (!selectedClasses.length) {
      return true;
    }
    if (!isComparableFull(comp) || !comp.comparable_transaction.energy_class) {
      return false;
    }
    return selectedClasses.includes(comp.comparable_transaction.energy_class);
  };
};

export const getDesignationTypeFilterFunction = (
  selectedTypes: DesignationType[]
) => {
  return (comp: Comparable) => {
    if (!selectedTypes.length) {
      return true;
    }
    const newTypes = [...selectedTypes];
    if (selectedTypes.includes(DesignationType.Manufacturing)) {
      newTypes.push(DesignationType.ManufacturingIndustry);
    }
    return newTypes.includes(comp.comparable_transaction.designation_type_id);
  };
};

export const getValuationZonesTypeFilterFunction = (
  selectedZones: string[]
) => {
  return (comp: Comparable) => {
    if (!selectedZones.length) {
      return true;
    }
    return selectedZones.includes(comp.comparable_transaction.valuation_zone);
  };
};

export const getFilterFunctionByType = (
  type: ComparableFilterType,
  values: any
): ((comp: Comparable) => boolean) => {
  switch (type) {
    case ComparableFilterType.Area:
      return (comp: Comparable) => {
        if (!isComparableFull(comp)) {
          return true;
        }
        return filterRange(comp, "area", values?.[0], values?.[1]);
      };
    case ComparableFilterType.Dates:
      return getMonthRangeFilterFunction(values.start, values.end);
    case ComparableFilterType.Floor:
      return getFloorFilterFunction(values);
    case ComparableFilterType.Year:
      return (comp: Comparable) => {
        return filterConstructionYear(comp, values?.[0], values?.[1]);
      };

    case ComparableFilterType.Polygon:
      return getPolygonFilterFunction(values);

    case ComparableFilterType.Bounds:
      return getBoundsFilterFunction(values);
    case ComparableFilterType.Finishing:
      return getFinishingFilterFunction(values);
    case ComparableFilterType.MatchedTransactions:
      return getMatchingTransactionFilterFunction(values);
    case ComparableFilterType.Walls:
      return getWallsTypeFilterFunction(values);
    case ComparableFilterType.EnergyClass:
      return getEnergyClassFilterFunction(values);
    case ComparableFilterType.Designation:
      return getDesignationTypeFilterFunction(values);
    case ComparableFilterType.ValuationZone:
      return getValuationZonesTypeFilterFunction(values);
    case ComparableFilterType.PriceArea:
      return getPriceAreaFilterFunction(values);
  }
};
