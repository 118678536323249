import React from "react";
import { Typography } from "common/components/Typography";
import { IconBadge } from "common/components/map/IconBadge";
import WbCloudyOutlinedIcon from "@mui/icons-material/WbCloudyOutlined";
import { PropertyAirPollutionRisk } from "../../types/appraiser.types";
import colors from "common/styles/colors";
import { ClimateRiskTypesTranslations } from "../../helpers/comparableFields.helpers";
import {
  determineBackgroundColor,
  getAirPollutionRiskDescription,
} from "../../helpers/climateRisk.helpers";
import { PropertyLocationMap } from "./PropertyLocationMap";
import {
  PropertyLocationDetailsType,
  PropertyLocationDetails,
} from "../../types/api.types";
import { PropertyClimateRiskWrapper } from "./PropertyClimateRiskWrapper";

interface PropertyAirPollutionRiskContentProps {
  airPollutionRisk?: PropertyAirPollutionRisk;
  locations: PropertyLocationDetails[];
  onRegenerate?: () => void;
}

export const PropertyAirPollutionRiskContent: React.FC<
  PropertyAirPollutionRiskContentProps
> = ({ airPollutionRisk, locations, onRegenerate }) => {
  const airPollutionLocation = locations.find(
    (location) =>
      location.locationType === PropertyLocationDetailsType.AIR_POLLUTION
  );

  return (
    <div
      style={{
        marginTop: "25px",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <PropertyClimateRiskWrapper
        title={<Typography variant="h5">Oro taršos rizika</Typography>}
        description={
          <Typography variant="caption">
            Vertinama pagal kietųjų dalelių (KD) koncentraciją ore, ypač
            miestuose, kur tarša didesnė dėl transporto ir pramonės.
          </Typography>
        }
        icon={
          <IconBadge
            width={40}
            height={40}
            backgroundColor={
              airPollutionRisk?.risk
                ? determineBackgroundColor(airPollutionRisk.risk)
                : colors.grey
            }
          >
            <WbCloudyOutlinedIcon
              fontSize="medium"
              style={{ color: "white" }}
            />
          </IconBadge>
        }
        riskType={
          <Typography variant="h4">
            {airPollutionRisk?.risk
              ? ClimateRiskTypesTranslations[airPollutionRisk.risk]
              : "Nenustatyta"}
          </Typography>
        }
        riskDescription={
          <Typography variant="caption">
            {airPollutionRisk?.risk
              ? getAirPollutionRiskDescription(airPollutionRisk.pm_10)
              : "Oro taršos rizika nenustatyta."}
          </Typography>
        }
      />
      {!!airPollutionLocation && (
        <div style={{ width: "45%" }}>
          <PropertyLocationMap
            locationDetails={airPollutionLocation}
            onRegenerate={onRegenerate}
            printableView={!onRegenerate}
          />
        </div>
      )}
    </div>
  );
};
