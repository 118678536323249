const colors = {
  black: "#000000",
  white: "#FFFFFF",
  grey: "#727272",
  grey2: "silver",
  beige: "#A6A69E",
  purple: "#5922E5",
  purple2: "#4D52C9",
  purple3: "#9497DF",
  purple4: "#EAEAF9",
  bg: "#F8F8F8",
  red: "#E74B41",
  green: "#6FA64B",
  purblebg: "rgba(77,82,201,0.2)",
  greybg: "#EFEFEF",
  greyicon: "#BBBBBB",
  greyborder: "#D7D7D7",
  lightgrey: "#EBEBEB",
  border: "#C4C4C4",
  premium: "#FFA500",
  chartOther: "#CCCCCC",
  orange: "#FFA500",
};

export default colors;
