import React from "react";
import { SimpleTableComponent } from "../tables/simpleTable/SimpleTableComponent";
import { SimpleTableRow } from "../tables/simpleTable/SimpleTableRow";
import { SimpleTableHeader } from "../tables/simpleTable/SimpleTableHeader";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { deleteCompositeAsset, getCompositeAssets } from "../../api/api";
import { DesignationTypeTranslations } from "../../helpers/comparableFields.helpers";
import { DesignationType } from "common/types/comparableFields.types";
import { SimpleTableElement } from "../tables/simpleTable/SimpleTableElement";
import { formatArea, formatPrice } from "common/helpers/text.helpers";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { IconButton, useMediaQuery } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { ConfirmationDialog } from "../dialogs/ConfirmationDialog";
import { CompositeAsset } from "../../types/api.types";
import { showToastError } from "common/toast/toast";
import { getErrorMessage } from "common/helpers/error.helpers";
import { CompositeAssetsForm } from "./CompositeAssetsForm";
import { theme } from "../../styles/MUITheme";

interface CompositeAssetsTableProps {
  propertyId: string;
  pricePredicitionId?: string;
  isCustomer?: boolean;
  readonly?: boolean;
  compositeAssets?: CompositeAsset[];
  hideValue?: boolean;
}

export const CompositeAssetsTable: React.FC<CompositeAssetsTableProps> = ({
  propertyId,
  isCustomer,
  pricePredicitionId,
  readonly,
  compositeAssets,
  hideValue,
}) => {
  const { data: fetchedData } = useQuery(
    ["compositeAssets", propertyId, pricePredicitionId],
    () => getCompositeAssets(propertyId, pricePredicitionId),
    {
      enabled: !compositeAssets,
    }
  );

  const data = compositeAssets ?? fetchedData;

  const queryClient = useQueryClient();

  const { mutate: mutateDelete, isLoading: isDeleting } = useMutation(
    (id: string) => deleteCompositeAsset(id, propertyId),
    {
      onSuccess: () => {
        setIsDeleteOpen(false);
        queryClient.invalidateQueries("compositeAssets");
        queryClient.invalidateQueries("report_price");
      },
      onError: (error) => {
        showToastError(getErrorMessage(error));
      },
    }
  );

  const totalSum = data?.reduce((acc, curr) => acc + (curr.value ?? 0), 0);

  const [selectedDeleteAsset, setSelectedDeleteAsset] =
    React.useState<CompositeAsset>();

  const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);

  const [selectedEditAsset, setSelectedEditAsset] =
    React.useState<CompositeAsset>();

  const [isEditOpen, setIsEditOpen] = React.useState(false);

  const isMobile = useMediaQuery(theme.breakpoints.down("sm")) && !readonly;

  return (
    <>
      <div
        style={{
          overflowX: "scroll",
        }}
      >
        <SimpleTableComponent
          customFontSize="14px"
          style={{
            minWidth: "500px",
            overflowX: "scroll",
          }}
        >
          <SimpleTableRow>
            <SimpleTableHeader isFirstElement>
              Turto pavadinimas
            </SimpleTableHeader>
            {!isMobile && (
              <SimpleTableHeader textAlign="left">
                Turto paskirtis
              </SimpleTableHeader>
            )}
            {!isMobile && (
              <SimpleTableHeader textAlign="left">
                Unikalus nr.
              </SimpleTableHeader>
            )}
            {!isMobile && (
              <SimpleTableHeader textAlign="left">Adresas</SimpleTableHeader>
            )}
            <SimpleTableHeader textAlign="right">
              Bendras plotas
            </SimpleTableHeader>
            <SimpleTableHeader textAlign="right">
              Vertinama dalis
            </SimpleTableHeader>

            {!isCustomer && !hideValue && (
              <SimpleTableHeader textAlign="right">
                Rinkos kaina
              </SimpleTableHeader>
            )}
            {!readonly && <SimpleTableHeader />}
          </SimpleTableRow>
          {data?.map((compositeAsset) => (
            <SimpleTableRow key={compositeAsset._id}>
              <SimpleTableElement isFirstElement>
                {compositeAsset.name}
              </SimpleTableElement>
              {!isMobile && (
                <SimpleTableElement textAlign="left">
                  {
                    DesignationTypeTranslations[
                      compositeAsset.designationTypeId as DesignationType
                    ]
                  }
                </SimpleTableElement>
              )}
              {!isMobile && (
                <SimpleTableElement textAlign="left">
                  {compositeAsset.uniqueNr}
                </SimpleTableElement>
              )}
              {!isMobile && (
                <SimpleTableElement textAlign="left">
                  {compositeAsset.address}
                </SimpleTableElement>
              )}
              <SimpleTableElement>
                {`${compositeAsset.areaTotal} ${compositeAsset.areaUnit}`}
              </SimpleTableElement>

              <SimpleTableElement>
                {compositeAsset.assetPart1}/{compositeAsset.assetPart2}
              </SimpleTableElement>

              {!isCustomer && !hideValue && (
                <SimpleTableElement>
                  {formatPrice(compositeAsset.value)}
                </SimpleTableElement>
              )}
              {!readonly && (
                <SimpleTableElement>
                  {compositeAsset._id !== "main" && (
                    <>
                      <IconButton
                        color="inherit"
                        onClick={() => {
                          setSelectedDeleteAsset(compositeAsset);
                          setIsDeleteOpen(true);
                        }}
                        disabled={
                          (isCustomer && !compositeAsset.isCustomer) ||
                          !!compositeAsset.isPlotArea
                        }
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                      <IconButton
                        color="inherit"
                        onClick={() => {
                          setSelectedEditAsset(compositeAsset);
                          setIsEditOpen(true);
                        }}
                        disabled={isCustomer && !compositeAsset.isCustomer}
                      >
                        <EditOutlinedIcon />
                      </IconButton>
                    </>
                  )}
                </SimpleTableElement>
              )}
            </SimpleTableRow>
          ))}
          {!isCustomer && !readonly && !hideValue && (
            <SimpleTableRow>
              <SimpleTableElement />
              <SimpleTableElement />
              <SimpleTableElement />
              <SimpleTableElement />
              <SimpleTableElement>
                <b>Iš viso:</b>
              </SimpleTableElement>
              <SimpleTableElement>
                <b>{formatPrice(totalSum)}</b>
              </SimpleTableElement>
              <SimpleTableElement />
            </SimpleTableRow>
          )}
        </SimpleTableComponent>
      </div>
      <ConfirmationDialog
        isOpen={isDeleteOpen}
        onClose={() => setIsDeleteOpen(false)}
        onConfirm={() => {
          if (selectedDeleteAsset) {
            mutateDelete(selectedDeleteAsset._id);
          }
        }}
        title="Ištrinti papildomą turtą"
        description={`Ar tikrai norite ištrinti "${selectedDeleteAsset?.name}" papildomą turtą?`}
        hideDoNotShowAgain
        actionButtonText="IŠTRINTI"
        isLoading={isDeleting}
      />
      <CompositeAssetsForm
        propertyId={propertyId}
        compositeAsset={selectedEditAsset}
        open={isEditOpen}
        onClose={() => setIsEditOpen(false)}
        isCustomer={isCustomer}
        hideValue={hideValue}
      />
    </>
  );
};
