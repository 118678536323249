import { Link } from "@mui/material";
import React, { useMemo } from "react";
import { useQuery } from "react-query";
import { getAdjustmentTable } from "../../api/api";
import { SimpleDialog } from "../../components/dialogs/SimpleDialog";
import BaseChart from "../../components/charts/BaseChart";
import { ChartDataItem, ChartType } from "../../types/api.types";
import { adjustmentFields } from "common/helpers/comparables.helpers";
import { ComparableTransactionFull } from "common/types/common.types";
import { LoaderCentered } from "../../components/loaders/LoaderCentered";

interface TranparencyAdjustmentsHeaderProps {
  field: keyof ComparableTransactionFull;
  provider: string | undefined;
  providerFullname: string | undefined;
  propertyId: string;
}

const fieldsToShowDetails: (keyof ComparableTransactionFull)[] = [
  "area",
  "rooms",
  "floor_position",
  "construction_year",
  "sold_date",
  "finishing",
];

export const TranparencyAdjustmentsHeader: React.FC<
  TranparencyAdjustmentsHeaderProps
> = ({ field, provider, providerFullname, propertyId }) => {
  const [open, setOpen] = React.useState(false);

  const { data, isLoading } = useQuery(
    ["adjustments_table", field, provider, propertyId],
    () => getAdjustmentTable(field, provider ?? "", propertyId),
    {
      enabled: !!provider && open,
    }
  );

  const dataPoints = useMemo(() => {
    if (!data) {
      return [];
    }

    const seen = new Set<string>();

    const chartData: ChartDataItem[] = [];

    const addPoint = (x: number, y: number) => {
      const key = `${x}-${y}`;
      if (seen.has(key)) {
        return;
      }

      seen.add(key);

      chartData.push({
        x,
        y,
      });
    };

    for (const item of data) {
      addPoint(item.range_min, item.coeff_min);
      addPoint(item.range_max, item.coeff_max);
    }

    chartData.sort((a: any, b: any) => a.x - b.x);

    // If we have more than 100 points, reduce them
    const maxDataPoints = 100;
    if (chartData.length > maxDataPoints) {
      const step = Math.floor(chartData.length / maxDataPoints);
      return chartData.filter(
        (_, index) =>
          index % step === 0 ||
          index + 1 === chartData.length ||
          index + 2 === chartData.length
      );
    }

    return chartData;
  }, [data]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ textAlign: "center" }}>
        Metodologija - {providerFullname}
      </div>
      {isLoading && <LoaderCentered />}
      {fieldsToShowDetails.includes(field) && (
        <>
          <Link component="button" onClick={() => setOpen(true)}>
            Detaliau
          </Link>
          <SimpleDialog
            open={open}
            onClose={() => setOpen(false)}
            title={`Pataisos koeficientai - ${adjustmentFields[field]?.fieldName} (${providerFullname})`}
            width="1300px"
            hideButton
          >
            <BaseChart
              data={dataPoints}
              chartType={ChartType.Lines}
              curve="linear"
            />
          </SimpleDialog>
        </>
      )}
    </div>
  );
};
