import React from "react";
import { useParams } from "react-router-dom";
import { ContentCard } from "../../components/ContentCard";
import { CompositeAssets } from "../../components/comparablesSummary/CompositeAssets";
import { LinkForButton } from "../../components/links/LinkForButton";
import { Button } from "@mui/material";
import { CenteredContentWrapper } from "../../components/wrappers/CenteredContentWrapper";

interface PropertyDetailsNextRouteProps {}

export const PropertyDetailsNextRoute: React.FC<
  PropertyDetailsNextRouteProps
> = () => {
  const { propertyId } = useParams();

  if (!propertyId) {
    return null;
  }

  return (
    <div>
      <ContentCard title="Priklausomas turtas">
        <CompositeAssets propertyId={propertyId} hideValue />
      </ContentCard>
      <CenteredContentWrapper>
        <LinkForButton to={`/properties/${propertyId}`}>
          <Button
            style={{ width: "200px", marginTop: "20px" }}
            variant="contained"
          >
            Tęsti
          </Button>
        </LinkForButton>
      </CenteredContentWrapper>
    </div>
  );
};
