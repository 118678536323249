import React from "react";
import colors from "common/styles/colors";

interface PropertyClimateRiskWrapperProps {
  title: React.ReactNode;
  description: React.ReactNode;
  riskType: React.ReactNode;
  riskDescription: React.ReactNode;
  icon: React.ReactNode;
}

export const PropertyClimateRiskWrapper: React.FC<
  PropertyClimateRiskWrapperProps
> = ({ title, description, riskType, riskDescription, icon }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        width: "45%",
      }}
    >
      {title}
      {description}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          border: `1px solid ${colors.greyborder}`,
          borderRadius: "10px",
          padding: "15px",
          height: "100px",
          justifyContent: "center",
        }}
      >
        <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
          {icon}
          {riskType}
        </div>
        {riskDescription}
      </div>
    </div>
  );
};
