import React from "react";
import { ContentCard } from "../../components/ContentCard";
import { AppraiserProperty } from "../../types/appraiser.types";
import { PropertySustainabilityReviewContent } from "./PropertySustainabilityReviewContent";
import { PropertyLocationDetails } from "../../types/api.types";

interface PropertySustainabilityReviewProps {
  property?: AppraiserProperty;
  locations: PropertyLocationDetails[];
  onRegenerate?: () => void;
}

export const PropertySustainabilityReview: React.FC<PropertySustainabilityReviewProps> = ({
  property, locations, onRegenerate
}) => {
  return (
    <ContentCard
      title="Tvarumo apžvalga"
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "30px",
      }}
    >
    <PropertySustainabilityReviewContent property={property} locations={locations} onRegenerate={onRegenerate} />
    </ContentCard>
  );
};
