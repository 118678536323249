import { stringToIntOrUndefined } from "common/helpers/text.helpers";
import React from "react";
import { NavigateOptions, useLocation, useNavigate } from "react-router-dom";
import { AppraiserFilter, PropertyAssessmentStatus } from "../types/api.types";
import { useAppraiser } from "./auth.hooks";
import { AppraiserRole } from "../types/appraiser.types";

export function useRouteQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function usePaginatedQuery() {
  const query = useRouteQuery();

  const page = query.get("page");
  const pageInt = stringToIntOrUndefined(page ?? "") ?? 1;

  return { page: pageInt };
}

export function usePropertyAssessmentStatusQuery() {
  const query = useRouteQuery();

  const status = query.get("status") ?? "";

  if (
    Object.values(PropertyAssessmentStatus).some((value) => value === status)
  ) {
    return status as PropertyAssessmentStatus;
  }

  return PropertyAssessmentStatus.New;
}

export function useAppraiserFilterQuery() {
  const query = useRouteQuery();

  const { data: appraiser } = useAppraiser();

  const appraiserFilter = query.get("appraiserFilter");

  if (
    Object.values(AppraiserFilter).some((value) => value === appraiserFilter)
  ) {
    return appraiserFilter as AppraiserFilter;
  }
  if (!appraiser?.role) {
    return null;
  }

  return appraiser.role === AppraiserRole.APPRAISER
    ? AppraiserFilter.My
    : AppraiserFilter.All;
}

export function useUpdateQueryParam() {
  const navigate = useNavigate();
  const location = useLocation();

  const getUpdatedQueryParam = (
    paramsToUpdate: {
      paramName: string;
      newValue: string;
    }[],
    paramsToDelete?: string[]
  ) => {
    const searchParams = new URLSearchParams(location.search);
    for (const { paramName, newValue } of paramsToUpdate) {
      searchParams.set(paramName, newValue);
    }
    for (const param of paramsToDelete ?? []) {
      searchParams.delete(param);
    }

    // Create a new location object with updated search parameters
    const updatedLocation = {
      pathname: location.pathname,
      search: searchParams.toString(),
    };

    return updatedLocation;
  };

  const updateQueryParam = (
    paramName: string,
    newValue: string,
    paramsToDelete?: string[],
    replace = false
  ) => {
    navigate(getUpdatedQueryParam([{ paramName, newValue }], paramsToDelete), {
      replace,
    });
  };

  const navigateWithQueryParams = (
    to: string,
    paramsToUpdate?: {
      paramName: string;
      newValue: string;
    }[],
    navigateOptions?: NavigateOptions
  ) => {
    const searchParams = getUpdatedQueryParam(paramsToUpdate ?? []).search;
    navigate(
      {
        pathname: to,
        search: searchParams,
      },
      navigateOptions
    );
  };

  return { updateQueryParam, getUpdatedQueryParam, navigateWithQueryParams };
}

export function useScrollToTop() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
}
