import React, { useContext, useEffect, useState } from "react";
import { getPricePredictionFilters } from "../api/api";
import { useParams } from "react-router-dom";
import { rcTransactionsProdMode } from "../api/constants";
import { showToastError } from "common/toast/toast";
import { ComparablesFiltersContext } from "./ComparablesFilterProvider";
import { LoaderCentered } from "./loaders/LoaderCentered";

interface InitialFiltersLoaderProps {
  children?: React.ReactNode;
}

export interface InitialFiltersLoaderContextProps {
  isInitialFiltersLoad: boolean;
}

export const InitialFiltersLoaderContext =
  React.createContext<InitialFiltersLoaderContextProps>({
    isInitialFiltersLoad: false,
  });

export const InitialFiltersLoaderProvider: React.FC<
  InitialFiltersLoaderProps
> = ({ children }) => {
  const { propertyId } = useParams();

  const [isLoadingFilters, setIsLoadingFilters] = useState(true);

  const [isInitialFiltersLoad, setIsInitialFiltersLoad] = useState(false);

  const { addSavedFilters } = useContext(ComparablesFiltersContext);

  const loadInitialFilters = async () => {
    let skipRefetch = false;

    try {
      const filters = await getPricePredictionFilters(propertyId ?? "");

      if (!filters?.length) {
        setIsInitialFiltersLoad(true);
        skipRefetch = true;
      }
      addSavedFilters?.(filters ?? []);
    } catch (e) {
      showToastError("Failed to load filters from server");
    }
    setIsLoadingFilters(false);
  };

  useEffect(() => {
    loadInitialFilters();
  }, []);
  return (
    <InitialFiltersLoaderContext.Provider value={{ isInitialFiltersLoad }}>
      {isLoadingFilters ? <LoaderCentered /> : children}
    </InitialFiltersLoaderContext.Provider>
  );
};
