import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Button } from "common/components/buttons/Button";
import colors from "common/styles/colors";
import { useMutation } from "react-query";
import { getErrorMessage } from "common/helpers/error.helpers";
import { useLocation, useNavigate } from "react-router-dom";
import { ContentCard } from "../../components/ContentCard";
import { showToastError } from "common/toast/toast";
import { PropertyCreationContext } from "./PropertyCreationProvider";
import { useRouteQuery, useUpdateQueryParam } from "../../hooks/router.hooks";
import { setPropertyToken } from "common/hooks/propertyDetails.hooks";
import {
  PropertyReadingSingleOverview,
  PropertyType,
} from "../../types/api.types";
import { Typography } from "common/components/Typography";
import { PropertyReadingTable } from "./PropertyReadingTable";
import { updateAppraiserProperty } from "../../api/appraiserProperties.api";
import { createCompositeAsset, getProperty } from "../../api/api";
import { MyProperty } from "common/types/common.types";

export const getAddressFromOverview = (
  overview: PropertyReadingSingleOverview
) => overview.data.property?.address || overview.data.asset?.address || "";

interface PropertyViaPDFConfirmRouteProps {}

export const PropertyViaPDFConfirmRoute: React.FC<
  PropertyViaPDFConfirmRouteProps
> = () => {
  const { navigateWithQueryParams } = useUpdateQueryParam();
  const navigate = useNavigate();
  const reading = useLocation().state.readings as
    | PropertyReadingSingleOverview[]
    | undefined;
  const myPropertyId = useLocation().state.myPropertyId as string | undefined;
  const { fromLead } = useContext(PropertyCreationContext);
  const appraiserId = useRouteQuery().get("appraiserId") ?? undefined;
  const simpleType = useRouteQuery().get("simpleType") as
    | PropertyType
    | undefined;

  const query = useRouteQuery();
  const designationType = query.get("type");

  const [flatProperties, setFlatProperties] = useState<
    PropertyReadingSingleOverview[]
  >([]);

  useEffect(() => {
    // transform read property to property overview for checkboxes
    // ignore if already transformed
    if (flatProperties.length > 0) return;
    if (!reading) {
      navigateWithQueryParams(
        fromLead ? "/lead/inputMethod" : "/properties/create/inputMethod"
      );
      return;
    }
    setFlatProperties(reading);
  }, [reading, navigateWithQueryParams, fromLead, flatProperties]);

  const uniqueAddresses = useMemo(() => {
    const addresses = flatProperties
      .map((x) => getAddressFromOverview(x))
      .filter((x) => !!x);
    return Array.from(new Set(addresses));
  }, [flatProperties]);

  const selectProperty = useCallback(
    (index: number, newValue: boolean) => {
      setFlatProperties((prev) =>
        prev.map((property, i) => ({
          ...property,
          isChecked: i === index ? newValue : property.isChecked,
        }))
      );
    },
    [setFlatProperties]
  );

  const selectAll = useCallback(
    (newValue: boolean) => {
      setFlatProperties((prev) =>
        prev.map((property) => ({ ...property, isChecked: newValue }))
      );
    },
    [setFlatProperties]
  );

  const { isLoading: isMutating, mutate } = useMutation(
    async () => {
      const readings = flatProperties.filter((property) => property.isChecked);
      const properties = readings
        .map((item) => item.data.property)
        .filter((property) => !!property);
      if (properties.length > 1) {
        throw new Error(
          `Tik vieną gyvenamą turtą galima pasirinkti. Pasirinkta: ${properties.length}`
        );
      }
      const propertyId = myPropertyId || "";
      const promises: Promise<any>[] = [];
      var myProperty: undefined | MyProperty = undefined;
      if (properties.length > 0) {
        const myProperty = properties[0];
        if (myProperty)
          // without IF: TS2345: Type 'MyPropertyAddRequest | undefined' is not assignable to type 'MyPropertyAddRequest'.
          promises.push(updateAppraiserProperty(myProperty, propertyId));
      }
      readings
        .map((reading) => reading.data.asset)
        .filter((asset) => !!asset)
        .forEach((asset) => {
          if (asset) {
            // without IF: TS2345:
            // Types of property 'propertyId' are incompatible.
            // Type 'string | undefined' is not assignable to type 'string'.
            promises.push(
              createCompositeAsset({ ...asset, isCustomer: !!fromLead })
            );
          }
        });
      await Promise.all(promises);
      if (!myProperty) {
        myProperty = await getProperty(propertyId);
      }
      return myProperty;
    },
    {
      // copy pasta from InputMethodSelectionRoute.tsx for property-via-url
      onSuccess: (property) => {
        if (!!fromLead) {
          setPropertyToken(property);
          navigateWithQueryParams(
            "/lead/details",
            [{ paramName: "createdPropertyId", newValue: property._id }],
            { replace: true }
          );
        } else {
          navigateWithQueryParams(
            `/properties/${property._id}/details`,
            undefined,
            { replace: true }
          );
        }
      },
      onError: (error: any) => {
        const message = getErrorMessage(error);

        if (message === undefined) {
          showToastError();
        } else {
          showToastError(message);
        }
      },
    }
  );

  return (
    <div>
      <ContentCard title="Pasirinkti adresus turto vertinimui">
        <Typography>
          Įkeltuose failuose rasta unikalių adresų: {uniqueAddresses.length}
        </Typography>
        <div>
          <PropertyReadingTable
            flattenedProperties={flatProperties}
            onSelectAll={selectAll}
            onPropertySelect={selectProperty}
          />
          <div style={{ marginTop: 30, width: "200px", justifySelf: "center" }}>
            <Button
              backgroundColor="purple2"
              textStyle={{ color: colors.white }}
              isLoading={isMutating}
              onPress={() => mutate()}
            >
              TĘSTI
            </Button>
          </div>
        </div>
      </ContentCard>
    </div>
  );
};
