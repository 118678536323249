import React, { useRef } from "react";
import { View, TextInput } from "react-native";
import { Typography } from "../Typography";
import { RadioButtonGroup, SelectType } from "../form/RadioButtonGroup";
import colors from "../../styles/colors";
import { IconButton } from "../buttons/IconButton";
import ClipboardIcon from "../../assets/icons/clipboard.svg";
import { getClipboard } from "../../clipboard/clipboard";
import { InputDocumentSelection } from "./InputDocumentSelection";

interface InputMethodSelectionProps {
  selectedInputMethod: InputMethod;
  onInputMethodChange: (method: InputMethod) => void;
  urlInput: string;
  onUrlChange: (urlInput: string) => void;
  noCaption?: boolean;
}

export enum InputMethod {
  Input = "input",
  Url = "url",
  Files = "files",
}

export const InputMethodSelection: React.FC<InputMethodSelectionProps> = ({
  selectedInputMethod,
  onInputMethodChange,
  urlInput,
  onUrlChange,
  noCaption,
}) => {
  const inputRef = useRef<TextInput>(null);

  const selectValues: SelectType<InputMethod>[] = [
    {
      label: "Įvesti informaciją",
      value: InputMethod.Input,
    },
    {
      label: "Įkelti skelbimo nuorodą",
      value: InputMethod.Url,
      contentUnderRadioButton: (
        <View
          style={{
            borderColor: colors.greyborder,
            borderWidth: 1,
            borderRadius: 5,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 15,
            opacity: selectedInputMethod !== InputMethod.Url ? 0.2 : 1,
          }}
          pointerEvents={
            selectedInputMethod !== InputMethod.Url ? "none" : "auto"
          }
        >
          <TextInput
            ref={inputRef}
            value={urlInput}
            onChangeText={(text) => {
              onUrlChange(text);
            }}
            placeholder="Skelbimo nuoroda"
            style={{ padding: 12, flex: 1 }}
            autoCapitalize="none"
            autoCorrect={false}
          />
          <IconButton
            onPress={async () => {
              const clipboardText = await getClipboard();
              if (!!clipboardText.length) {
                onUrlChange(clipboardText);
              }
            }}
          >
            <ClipboardIcon />
          </IconButton>
        </View>
      ),
    },
    {
      label: "Įkelti dokumentą",
      value: InputMethod.Files,
    },
  ];

  return (
    <View>
      {!noCaption && (
        <Typography variant="caption">
          Pasirinkite kokiu būdu norėtumėte sukurti turtą.
        </Typography>
      )}
      <RadioButtonGroup
        values={selectValues}
        selectedValue={selectedInputMethod}
        onSelect={(value) => {
          if (value !== InputMethod.Url) {
            inputRef.current?.blur();
          }
          onInputMethodChange(value);
        }}
      />
    </View>
  );
};
