import React from "react";
import { Typography } from "common/components/Typography";
import { SimpleDialog } from "web-valuations/src/components/dialogs/SimpleDialog";
import { LinkForButton } from "web-valuations/src/components/links/LinkForButton";

interface DocumentUploadHelperDialogProps {
  open: boolean;
  onClose: () => void;
}

export const DocumentUploadHelperDialog: React.FC<
  DocumentUploadHelperDialogProps
> = ({ open, onClose }) => {
  const divStyling: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  };

  return (
    <SimpleDialog
      open={open}
      onClose={onClose}
      title="Kokius dokumentus reikia įkelti?"
      hideButton
    >
      <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <div style={divStyling}>
          <Typography variant="h4">
            1. Nekilnojamo turto registro duomenų bazės išrašas
          </Typography>
          <Typography variant="caption">
            Dokumentas, kuriame pateikiama informacija apie jūsų turtą:
            savininkai, plotas, unikalus numeris ir kt.
          </Typography>
          <Typography variant="caption">
            Užsisakykite šį išrašą VĮ Registrų centre{" "}
            <LinkForButton to="https://www.registrucentras.lt/p/1531">
              <Typography variant="link">internetu</Typography>
            </LinkForButton>{" "}
            arba atvykę į jų skyrių.
          </Typography>
        </div>
        <div style={divStyling}>
          <Typography variant="h4">2. Turto vertinimo ataskaita</Typography>
          <Typography variant="caption">
            Jei turite ankstesnį turto vertinimą, įkelkite šią ataskaitą. Ji
            padės geriau suprasti Jūsų turto vertę.
          </Typography>
        </div>
        <div style={divStyling}>
          <Typography variant="h4">3. Kadastriniai planai</Typography>
          <Typography variant="caption">
            Dokumentai, kuriuose pateikiami sklypo ar būsto matavimai ir ribos.
          </Typography>
          <Typography variant="caption">
            Užsisakykite juos VĮ Registrų centre arba kreipkitės į matininką,
            kuris atliks matavimus.
          </Typography>
        </div>
        <div>
          <Typography variant="h5">
            Įkelkite turimus dokumentus. Kuo daugiau informacijos pateiksite,
            tuo tiksliau galėsime įvertinti Jūsų turtą.
          </Typography>
        </div>
      </div>
    </SimpleDialog>
  );
};
