import React from "react";
import { useQuery } from "react-query";
import { getPropertyLocationDetails } from "../../api/api";
import { StyledImage } from "common/components/images/StyledImage";
import { ContentCard } from "../../components/ContentCard";
import { PropertyLocationDetailsType, PropertyLocationDetails } from "../../types/api.types";
import { PropertyLocationMap } from "./PropertyLocationMap";
import { Grid } from "@mui/material";
import { PropertyLocationContent } from "./PropertyLocationContent";

interface PropertyLocationProps {
  locations: PropertyLocationDetails[];
  onRegenerate?: () => void;
}

const order = [
  PropertyLocationDetailsType.REGIA1,
  PropertyLocationDetailsType.REGIA2,
  PropertyLocationDetailsType.FLOODS,
];

export const PropertyLocation: React.FC<PropertyLocationProps> = ({
  locations, onRegenerate
}) => {

  if (!locations) return null;

  return (
    <PropertyLocationContent locations={locations} onRegenerate={onRegenerate} />
  );
};
