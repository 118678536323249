import React, { useMemo } from "react";
import { ContentCard } from "../../components/ContentCard";
import { SimpleTableComponent } from "../../components/tables/simpleTable/SimpleTableComponent";
import { SimpleTableRow } from "../../components/tables/simpleTable/SimpleTableRow";
import { SimpleTableHeader } from "../../components/tables/simpleTable/SimpleTableHeader";
import {
  Comparable,
  ComparableFull,
  ComparableTransactionFull,
  FinalPricePredictions,
} from "common/types/common.types";
import { SimpleTableElement } from "../../components/tables/simpleTable/SimpleTableElement";
import { round } from "common/helpers/common.helpers";
import { adjustmentFields } from "common/helpers/comparables.helpers";
import colors from "common/styles/colors";
import { addAlpha } from "common/helpers/colors.helpers";
import { BasePropertyCard } from "../../components/cards/BasePropertyCard";
import { ComparableCard } from "../../components/map/ComparableCard";
import { Chip } from "@mui/material";
import {
  comparableFilterTypeToLabel,
  extendedFilters,
  getFilterValues,
  otherFilters,
} from "../../helpers/comparableFilters.helpers";
import { useMessagesStatus } from "../../components/messages/hooks";

interface TransparencySimilarityProps {
  data: FinalPricePredictions;
}

export const TransparencySimilarity: React.FC<TransparencySimilarityProps> = ({
  data,
}) => {
  const fields = useMemo(() => {
    const fieldsSet = new Set<string>();

    data?.comparables.forEach((c) => {
      c.similarities?.similarities_by_field?.forEach((f) => {
        fieldsSet.add(f.field);
      });
    });

    return Array.from(fieldsSet);
  }, [data]);

  const getFieldSimilarity = (
    comp: ComparableFull | Comparable,
    field: string
  ) => {
    return (
      comp.similarities?.similarities_by_field?.find((f) => f.field === field)
        ?.score ?? null
    );
  };

  const mergedComps = useMemo(() => {
    let compsFromResults = [
      ...(data.rc_results?.full_transactions ?? []),
      ...(data.rc_results?.base_transactions ?? []),
    ];

    const sortComps = () => {
      compsFromResults.sort((a, b) => {
        return (
          b.similarities.joint_similarity - a.similarities.joint_similarity
        );
      });
    };

    compsFromResults = compsFromResults.filter(
      (c) =>
        !data.comparables.some(
          (comp) =>
            comp.comparable_transaction.id === c.comparable_transaction.id
        )
    );

    sortComps();

    compsFromResults.push(...data.comparables);

    sortComps();

    return compsFromResults;
  }, [data]);

  const { data: messagesStatus } = useMessagesStatus();

  return (
    <ContentCard title="Panašumo koeficientai">
      <div style={{ marginBottom: "20px" }}>
        <div style={{ marginBottom: "10px" }}>
          Kandidatai atrinkti pagal šiuos filtrus:
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            flexWrap: "wrap",
          }}
        >
          {data.applied_filters?.map((f) => {
            if (
              otherFilters.includes(f.type) ||
              extendedFilters.includes(f.type)
            ) {
              return null;
            }

            return (
              <Chip
                key={f.type}
                label={`${
                  comparableFilterTypeToLabel[f.type]
                }: ${getFilterValues(f)}`}
                variant="outlined"
              />
            );
          })}
        </div>
      </div>
      <SimpleTableComponent>
        <SimpleTableRow>
          <SimpleTableHeader isFirstElement>
            Palyginamasis objektas
          </SimpleTableHeader>
          <SimpleTableHeader>Panašumas</SimpleTableHeader>
          {fields.map((f) => (
            <SimpleTableHeader key={f}>
              {adjustmentFields[f as keyof ComparableTransactionFull]
                ?.fieldName ?? (f === "distance" ? "Atstumas" : f)}
            </SimpleTableHeader>
          ))}
        </SimpleTableRow>
        {mergedComps.map((c) => {
          const status = messagesStatus?.[c.comparable_transaction.id];
          return (
            <SimpleTableRow
              key={c.comparable_transaction.id}
              style={{
                backgroundColor: data.comparables.some(
                  (comp) =>
                    comp.comparable_transaction.id ===
                    c.comparable_transaction.id
                )
                  ? addAlpha(colors.purple2, 0.2)
                  : undefined,
              }}
            >
              <SimpleTableElement isFirstElement>
                <ComparableCard
                  comparable={c}
                  isOverview
                  noBorder
                  status={status}
                />
              </SimpleTableElement>
              <SimpleTableElement
                style={{
                  textAlign: "center",
                }}
              >
                <b>{round(c.similarities.joint_similarity, 2).toFixed(2)}</b>
              </SimpleTableElement>
              {fields.map((f, index) => (
                <SimpleTableElement
                  key={f}
                  style={{
                    paddingRight: index === fields.length - 1 ? 10 : undefined,
                    textAlign: "center",
                    width: "100px",
                  }}
                >
                  {round(getFieldSimilarity(c, f) ?? 0, 2).toFixed(2)}
                </SimpleTableElement>
              ))}
            </SimpleTableRow>
          );
        })}
      </SimpleTableComponent>
    </ContentCard>
  );
};
