import React from "react";
import {
  PropertyLocationDetails,
  PropertyLocationDetailsType,
} from "../../types/api.types";
import { StyledImage } from "common/components/images/StyledImage";
import { Button, CircularProgress } from "@mui/material";
import { LinkForButton } from "../../components/links/LinkForButton";
import { IconButton } from "common/components/buttons/IconButton";
import LaunchIcon from "@mui/icons-material/Launch";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { regenerateLocationDetails } from "../../api/api";
import { useMutation } from "react-query";
import ReplayIcon from "@mui/icons-material/Replay";

interface PropertyLocationMapProps {
  locationDetails: PropertyLocationDetails;
  onRegenerate?: () => void;
  printableView?: boolean;
}

const getLocationLabel = (locationType: PropertyLocationDetailsType) => {
  switch (locationType) {
    case PropertyLocationDetailsType.REGIA1:
      return "REGIA";
    case PropertyLocationDetailsType.REGIA2:
      return "REGIA sklypai";
    case PropertyLocationDetailsType.FLOODS:
      return "Potvyniai";
    case PropertyLocationDetailsType.AIR_POLLUTION:
      return "Oro tarša";
    case PropertyLocationDetailsType.PROTECTED_TERRITORY:
      return "Saugoma teritorija";
    default:
      return locationType;
  }
};

export const PropertyLocationMap: React.FC<PropertyLocationMapProps> = ({
  locationDetails,
  onRegenerate,
  printableView,
}) => {
  const { mutate: regenerate, isLoading: isRegenerating } = useMutation(
    regenerateLocationDetails,
    {
      onSuccess: () => {
        onRegenerate?.();
      },
    }
  );

  const isRetryDisabled =
    !locationDetails.generatingCompleted || isRegenerating;

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            fontWeight: 600,
          }}
        >
          {getLocationLabel(locationDetails.locationType)}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          {!!locationDetails.url && (
            <LinkForButton target="_blank" to={locationDetails.url}>
              <IconButton>
                <LaunchIcon />
              </IconButton>
            </LinkForButton>
          )}
          {!!locationDetails.screenshotUrl && !printableView && (
            <LinkForButton
              to={locationDetails.screenshotUrl}
              download={`${locationDetails.locationType}-${locationDetails.propertyId}.jpeg`}
              target="_blank"
              disabled={isRetryDisabled}
            >
              <IconButton isDisabled={isRetryDisabled}>
                <FileDownloadOutlinedIcon />
              </IconButton>
            </LinkForButton>
          )}
          {!printableView && (
            <IconButton
              isDisabled={isRetryDisabled}
              onPress={() => {
                if (isRetryDisabled) return;
                regenerate(locationDetails._id);
              }}
            >
              <ReplayIcon />
            </IconButton>
          )}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
          height: "300px",
        }}
      >
        {!locationDetails.generatingCompleted ? (
          <CircularProgress />
        ) : locationDetails.screenshotUrl ? (
          <StyledImage
            imageProps={{
              source: { uri: locationDetails.screenshotUrl },
              resizeMode: "contain",
            }}
            height={300}
          />
        ) : (
          "Nepavyko sugeneruoti"
        )}
      </div>
    </div>
  );
};
