import React from "react";
import { PropertyLocation } from "./PropertyLocation";
import { useQuery } from "react-query";
import { getPropertyInfra, getPropertyLocationDetails } from "../../api/api";
import { useProperty } from "../../hooks/property.hooks";
import { PropertyDistances } from "./distances/PropertyDistances";
import { CircularProgress } from "@mui/material";
import { PropertySustainabilityReview } from "./PropertySustainabilityReview";

interface PropertyLocationReportProps {
  propertyId: string;
}

export const PropertyLocationReport: React.FC<PropertyLocationReportProps> = ({
  propertyId,
}) => {
  const { data, isLoading } = useQuery(["property_infra", propertyId], () =>
    getPropertyInfra(propertyId)
  );

  const { data: property } = useProperty(propertyId);

  const { data: propertyLocation, refetch } = useQuery(
    ["property_location", propertyId],
    () => getPropertyLocationDetails(propertyId),
    {
      refetchInterval: (data) =>
        data?.some((location) => !location.generatingCompleted) ? 5000 : false,
    }
  );

  return (
    <div>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          {!!data && !!property && (
            <PropertyDistances infras={data} property={property} />
          )}
        </>
      )}
      {propertyLocation && (
        <>
        <PropertySustainabilityReview
          property={property}
          locations={propertyLocation}
          onRegenerate={() => refetch()}
        />
        <PropertyLocation locations={propertyLocation} onRegenerate={() => refetch()}/>
        </>
      )}
    </div>
  );
};
