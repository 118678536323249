import React from "react";
import {
  MAX_PERCENT_DIFF,
  MAX_TRANSACTIONS,
  useAutoPredictPrice,
} from "../../hooks/pricePredictions.hooks";
import { AppraiserProperty } from "../../types/appraiser.types";
import { ConfirmationDialog } from "../dialogs/ConfirmationDialog";
import { Button, Radio, RadioGroup } from "@mui/material";
import { CheckboxInput } from "common/components/form/CheckboxInput";
import { SmallNumberInput } from "common/components/form/SmallNumberInput";
import { Typography } from "common/components/Typography";
import { useNavigate } from "react-router-dom";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

interface AutoPricePredictionProps {
  property: AppraiserProperty;
  buttonText?: string;
  skipNavigation?: boolean;
}

export const AutoPricePrediction: React.FC<AutoPricePredictionProps> = ({
  property,
  buttonText,
  skipNavigation,
}) => {
  const { autoPredictPrice, isLoading } = useAutoPredictPrice();

  const [confirmationOpen, setConfirmationOpen] = React.useState(false);

  type Method = "AVOID_OUTLIERS" | "DEFAULT";

  const [selectedMethod, setSelectedMethod] = React.useState<Method>("DEFAULT");

  const [maxTransactionsToBuy, setMaxTransactionsToBuy] =
    React.useState(MAX_TRANSACTIONS);

  const handleChange = (event: any) => {
    setSelectedMethod(event.target.value);
  };

  const navigate = useNavigate();
  return (
    <>
      <Button
        onClick={() => {
          setConfirmationOpen(true);
        }}
        variant="outlined"
      >
        {buttonText ?? "Įvertinti automatiškai"}
      </Button>

      <ConfirmationDialog
        title="Automatinė vertė"
        isOpen={confirmationOpen}
        onClose={() => {
          if (isLoading) return;
          setConfirmationOpen(false);
        }}
        hideDoNotShowAgain
        actionButtonText="SKAIČIUOTI"
        isLoading={isLoading}
        onConfirm={async () => {
          await autoPredictPrice(
            property,
            undefined,
            selectedMethod === "AVOID_OUTLIERS",
            maxTransactionsToBuy
          );
          setConfirmationOpen(false);
          if (!skipNavigation) {
            navigate("comparables/summary");
          }
        }}
        width="650px"
      >
        <RadioGroup onChange={handleChange} value={selectedMethod}>
          <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
            <Radio value="DEFAULT" />
            <p>leisti įsigyti 3 panašiausius sandorius</p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              alignItems: "start",
            }}
          >
            <Radio
              style={{ marginTop: "5px" }}
              onChange={handleChange}
              value="AVOID_OUTLIERS"
            />
            <div>
              <div
                style={{ display: "flex", flexDirection: "row", gap: "10px" }}
              >
                <p>leisti pasirinkti optimalų sandorių skaičių</p>
                <SmallNumberInput
                  value={maxTransactionsToBuy}
                  onChange={(value) => {
                    setMaxTransactionsToBuy(value);
                    setSelectedMethod("AVOID_OUTLIERS");
                  }}
                  minValue={3}
                  maxValue={20}
                  customStyle={{ width: 120, marginLeft: 10 }}
                />
              </div>
              <Typography variant="caption">
                Modelis papildomai įsigys tiek sandorių, kiek reikia, kad vertės
                skirtumas neviršytų 20%, tačiau ne daugiau nei pasirinktas
                optimalus sandorių skaičių.
              </Typography>
            </div>
          </div>

          <p
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              marginLeft: "20px",
            }}
          >
            <WarningAmberIcon color="warning" />
            Jei tęsite atlikti skaičiavimus, visi ankstesni skaičiavimai bus
            prarasti.
          </p>
        </RadioGroup>
      </ConfirmationDialog>
    </>
  );
};
