import React, { useEffect, useMemo, useRef } from "react";
import { ContentCard } from "../components/ContentCard";
import { useNavigate, useParams } from "react-router-dom";
import {
  useComparablesSummary,
  usePricePredictions,
} from "../hooks/pricePredictions.hooks";
import { useMutation, useQuery } from "react-query";
import { FinalPricePredictions } from "common/types/common.types";
import {
  addPricePrediction,
  createConfirmation,
  getConfidenceMeasureForPricePrediction,
} from "../api/api";
import { MUILoadingButton } from "../components/buttons/MUILoadingButton";
import { ComparablesSummaryTable } from "../components/comparablesSummary/ComparablesSummaryTable";
import { ValuationSummary } from "../components/comparablesSummary/ValuationSummary";
import {
  AdjustmentsJustification,
  AdjustmentsJustificationHandles,
} from "../components/comparablesSummary/AdjustmentsJustification";
import { CompositeAssets } from "../components/comparablesSummary/CompositeAssets";
import { PredictionsConfirmButton } from "./PredictionsConfirmButton";
import {
  ConfirmationForm,
  ConfirmationFormHandles,
} from "../components/comparablesSummary/ConfirmationForm";
import { ConfirmationRequest } from "../types/api.types";
import { showToastError } from "common/toast/toast";
import { useProperty } from "../hooks/property.hooks";
import { getErrorMessage } from "common/helpers/error.helpers";
import { useDeletePropertyToken } from "common/hooks/propertyDetails.hooks";
import { PredictionsConfidenceMeasure } from "./property/PredictionsConfidenceMeasure";
import { LinkForButton } from "../components/links/LinkForButton";
import { Button } from "@mui/material";
import { AutoPricePrediction } from "../components/predictionOverview/AutoPricePrediction";

interface ComparablesSummaryProps {}

export const ComparablesSummary: React.FC<ComparablesSummaryProps> = () => {
  const { propertyId } = useParams();

  useDeletePropertyToken(propertyId ?? "");

  const { getFinalPricePredictions, pricePredictions, refetch } =
    usePricePredictions(propertyId ?? "");

  useEffect(() => {
    refetch();
  }, []);

  const navigate = useNavigate();
  const {
    mutateAsync: mutateAsyncPredictions,
    isLoading: isLoadingPredictions,
  } = useMutation(async (pricePrediction: FinalPricePredictions) => {
    await addPricePrediction<FinalPricePredictions>(
      propertyId ?? "",
      pricePrediction,
      true
    );
  });

  const {
    mutateAsync: mutateAsyncConfirmation,
    isLoading: isLoadingConfirmation,
  } = useMutation(
    async (x: { data: ConfirmationRequest; propertyId: string }) =>
      createConfirmation(x.data, x.propertyId),
    {
      onError: (error) => {
        showToastError(getErrorMessage(error));
      },
    }
  );

  const [buttonPressed, setButtonPressed] = React.useState<"save" | "confirm">(
    "save"
  );

  const { comps } = useComparablesSummary(propertyId ?? "");

  const finalPricePredictions = useMemo(
    () => getFinalPricePredictions(),
    [getFinalPricePredictions]
  );

  const navigateToProperty = () => {
    navigate(`/properties/${propertyId}`);
  };

  const saveFinalPricePredictions = async (navigateOnSuccess: boolean) => {
    const final = getFinalPricePredictions();
    if (!final) return;
    final.descriptions = adjustmentsJustificationRef.current?.getDescriptions();
    await mutateAsyncPredictions(final);

    if (navigateOnSuccess) {
      navigateToProperty();
    }
  };

  const adjustmentsJustificationRef =
    React.useRef<AdjustmentsJustificationHandles>(null);

  const confirmationFormRef = useRef<ConfirmationFormHandles>(null);

  const isLoading = isLoadingConfirmation || isLoadingPredictions;

  const { data: property } = useProperty(propertyId ?? "");

  const { data: confidence } = useQuery(["confidence", propertyId], () =>
    getConfidenceMeasureForPricePrediction(propertyId ?? "")
  );

  if (!propertyId) return null;

  const handleConfirmationSubmit = async () => {
    const fromLead = !!property?.leadContact;

    if (property?.reportVerifiedAt) {
      await saveFinalPricePredictions(!fromLead);
      return true;
    }

    let error = false;
    await confirmationFormRef.current?.handleSubmit(
      async (data) => {
        setButtonPressed(fromLead ? "confirm" : "save");

        await mutateAsyncConfirmation({
          data,
          propertyId,
        });

        await saveFinalPricePredictions(!fromLead);
      },
      (data) => {
        if (data.confirmCheckbox) {
          showToastError("Patvirtinkite, kad sutinkate su sąlygomis");
        }
        error = true;
      }
    )();
    if (error) {
      return false;
    }
    return true;
  };

  return (
    <div style={{ padding: "15px 0" }}>
      {confidence && (
        <ContentCard title="Pasitikėjimo indeksas" borderRadius={0}>
          <PredictionsConfidenceMeasure confidence={confidence} />
        </ContentCard>
      )}
      <ContentCard
        title="Turto vertinimo informacija"
        borderRadius={0}
        icon={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
            }}
          >
            {!!property && (
              <AutoPricePrediction
                property={property}
                skipNavigation
                buttonText="Įvertinti automatiškai iš naujo"
              />
            )}
            <LinkForButton to="../">
              <Button color="error">Redaguoti</Button>
            </LinkForButton>
          </div>
        }
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ComparablesSummaryTable
            finalPricePredictions={finalPricePredictions}
            propertyId={propertyId ?? ""}
            comps={comps}
          />
        </div>
        <ValuationSummary
          finalPricePredictions={finalPricePredictions}
          propertyId={propertyId ?? ""}
        />
        <div
          style={{
            height: "30px",
          }}
        />
        <AdjustmentsJustification
          ref={adjustmentsJustificationRef}
          comps={comps}
        />
      </ContentCard>
      <ContentCard title="Priklausomas turtas" borderRadius={0}>
        <CompositeAssets
          propertyId={propertyId}
          pricePredictionId={pricePredictions?._id}
        />
      </ContentCard>
      <ContentCard title="Patvirtinimas">
        <ConfirmationForm
          ref={confirmationFormRef}
          disabled={!!property?.reportVerifiedAt}
          propertyId={propertyId}
        />
      </ContentCard>
      <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
        <MUILoadingButton
          loading={isLoading && buttonPressed === "save"}
          disabled={isLoading}
          onClick={
            property?.leadContact
              ? async () => {
                  setButtonPressed("save");
                  await saveFinalPricePredictions(true);
                }
              : handleConfirmationSubmit
          }
          style={{
            width: "300px",
          }}
          variant="outlined"
        >
          Išsaugoti ir uždaryti
        </MUILoadingButton>
        <PredictionsConfirmButton
          disabled={isLoading}
          loading={isLoading && buttonPressed === "confirm"}
          propertyId={propertyId}
          onClick={handleConfirmationSubmit}
          onConfirm={navigateToProperty}
        />
      </div>
    </div>
  );
};
