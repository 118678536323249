import React from "react";
import { AppraiserProperty } from "../../types/appraiser.types";
import { Typography } from "common/components/Typography";
import { PropertyLocationDetails } from "../../types/api.types";
import { PropertyFloodRiskContent } from "./propertyFloodRiskContent";
import { PropertyHeatRiskContent } from "./propertyHeatRiskContent";
import { PropertyWindRiskContent } from "./propertyWindRiskContent";
import { PropertyAirPollutionRiskContent } from "./propertyAirPollutionRiskContent";

interface PropertySustainabilityReviewContentProps {
  property?: AppraiserProperty;
  locations: PropertyLocationDetails[];
  onRegenerate?: () => void;
}

export const PropertySustainabilityReviewContent: React.FC<
  PropertySustainabilityReviewContentProps
> = ({ property, locations, onRegenerate }) => {
  if (!property) return null;

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
      <Typography variant="h4">Klimato rizikos vertinimas</Typography>
      <Typography variant="caption">
        Vertinant klimato riziką nekilnojamojo turto objektams, naudojami
        naujausi duomenys ir taikoma speciali metodologija, kuri apima rizikos
        vertinimą pagal tris lygius: maža, vidutinė arba didelė. Kiekvienas šis
        lygis žymi tikimybę, kad objektas gali būti paveiktas klimato pokyčių,
        tokių kaip potvyniai, karščio bangos, stiprus vėjas ar oro tarša.
      </Typography>
        <PropertyFloodRiskContent
          floodRisk={property.floodRisk}
          locations={locations}
          onRegenerate={onRegenerate}
        />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <PropertyHeatRiskContent heatRisk={property.heatRisk} />
        <PropertyWindRiskContent windRisk={property.windRisk} />
      </div>

      <PropertyAirPollutionRiskContent
        airPollutionRisk={property.airPollutionRisk}
        locations={locations}
        onRegenerate={onRegenerate}
      />
    </div>
  );
};
