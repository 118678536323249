import React from "react";
import { View } from "react-native";
import colors from "../../styles/colors";
import { Typography } from "../Typography";

interface IconBadgeProps {
  count?: number | string;
  width?: number;
  height?: number;
  backgroundColor?: string;
  children?: React.ReactNode;
}

export const IconBadge: React.FC<IconBadgeProps> = ({
  count,
  width,
  height,
  backgroundColor,
  children,
}) => {
  return (
    <View
      style={{
        width: width || 20,
        height: height || 20,
        backgroundColor: backgroundColor || colors.purple2,
        overflow: "visible",
        padding: 2,
        borderRadius: 20,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {children ? (
        children
      ) : (
        <Typography
          textStyle={{
            color: colors.white,
            fontSize: 12,
            fontWeight: "500",
          }}
        >
          {count}
        </Typography>
      )}
    </View>
  );
};
