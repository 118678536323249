import { Box, useMediaQuery } from "@mui/material";
import React from "react";
import { theme } from "../../styles/MUITheme";
import colors from "common/styles/colors";
import { StyledImage } from "common/components/images/StyledImage";
import { InfoRow } from "./InfoRow";
import { Confirmation } from "../../types/api.types";
import { getLithuanianDateString } from "common/helpers/dateStringHelpers";
import { confirmationText } from "../../components/comparablesSummary/ConfirmationForm";

interface BasicInfoCardProps {
  imageUrl?: string;
  name: string;
  description?: string | null;
  infoRows: { label: string; value?: string | null }[];
  logoUrl?: string;
  marginTop?: number;
  confirmation?: Confirmation | null;
  resizeMode?: "contain" | "cover";
  showOrganizationAsCustomer?: string;
}

export const BasicInfoCard: React.FC<BasicInfoCardProps> = ({
  imageUrl,
  name,
  description,
  infoRows,
  logoUrl,
  marginTop,
  confirmation,
  resizeMode,
  showOrganizationAsCustomer,
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const imageSize = isMobile ? 250 : 240;

  return (
    <Box
      sx={{
        backgroundColor: colors.white,
        borderRadius: "10px",
        pt: { xs: "20px", sm: 0 },
        my: "30px",
        mx: { xs: "10px", md: 0 },
        mt: marginTop,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: { sm: "row", xs: "column" },
          alignItems: { xs: "center", sm: "flex-start" },
          gap: "20px",
        }}
      >
        <div>
          <StyledImage
            width={imageSize}
            height={imageSize}
            borderRadius={isMobile ? 125 : undefined}
            borderBottomLeftRadius={10}
            borderTopLeftRadius={10}
            imageProps={{ source: { uri: imageUrl }, resizeMode }}
          />
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
            display: "flex",
            flexDirection: "column",
            minHeight: imageSize,
          }}
        >
          <Box
            sx={{
              fontSize: 20,
              fontWeight: 600,
              textAlign: { xs: "center", sm: "left" },
            }}
          >
            {name}
          </Box>
          <Box
            sx={{
              color: colors.grey,
              marginBottom: "20px",
              textAlign: { xs: "center", sm: "left" },
            }}
          >
            {description}
          </Box>
          {infoRows.map((infoRow) => {
            if (!infoRow.value) return null;
            return (
              <InfoRow
                key={infoRow.label}
                label={infoRow.label}
                value={infoRow.value}
              />
            );
          })}
          {!!logoUrl && (
            <div style={{ display: "flex", marginTop: "auto" }}>
              <StyledImage
                height={50}
                imageProps={{ source: { uri: logoUrl }, resizeMode: "contain" }}
              />
            </div>
          )}
        </div>
      </Box>
      {!!showOrganizationAsCustomer && (
        <div style={{ paddingLeft: "20px", paddingTop: "10px" }}>
          <InfoRow label="Užsakovas" value={showOrganizationAsCustomer} />
        </div>
      )}
      {!!confirmation?.confirmCheckbox && (
        <div style={{ padding: "20px" }}>
          <InfoRow
            label="NT vertintojo kvalifikacijos pažymėjimas"
            value={confirmation?.certificateNumber ?? "-"}
            customLabelWidth="300px"
          />
          <InfoRow
            label="Pažymėjimas išduotas"
            value={
              confirmation?.certificateValidFrom
                ? getLithuanianDateString(confirmation?.certificateValidFrom)
                : "-"
            }
            customLabelWidth="300px"
          />
          <p>{confirmationText}</p>
          <InfoRow
            label="Patvirtinimo data"
            value={getLithuanianDateString(confirmation.createdAt)}
          />
          <i>{confirmation.appraiserFullNameConfirm}</i>
        </div>
      )}
    </Box>
  );
};
