import React from "react";
import { Typography } from "common/components/Typography";
import { IconBadge } from "common/components/map/IconBadge";
import AirOutlinedIcon from "@mui/icons-material/AirOutlined";
import colors from "common/styles/colors";
import { PropertyWindRisk } from "../../types/appraiser.types";
import { ClimateRiskTypesTranslations } from "../../helpers/comparableFields.helpers";
import {
  determineBackgroundColor,
  getWindRiskDescription,
} from "../../helpers/climateRisk.helpers";
import { PropertyClimateRiskWrapper } from "./PropertyClimateRiskWrapper";

interface PropertyWindRiskContentProps {
  windRisk?: PropertyWindRisk;
}

export const PropertyWindRiskContent: React.FC<
  PropertyWindRiskContentProps
> = ({ windRisk }) => {
  return (
    <PropertyClimateRiskWrapper
      title={<Typography variant="h5">Vėjo rizika</Typography>}
      description={
        <Typography variant="caption">
          Vertinama pagal vėjo gūsius, kurie viršija 15 m/s ir gali padaryti
          žalą.
        </Typography>
      }
      icon={
        <IconBadge
          width={40}
          height={40}
          backgroundColor={
            windRisk?.risk
              ? determineBackgroundColor(windRisk.risk)
              : colors.grey
          }
        >
          <AirOutlinedIcon fontSize="medium" style={{ color: "white" }} />
        </IconBadge>
      }
      riskType={
        <Typography variant="h4">
          {windRisk?.risk
            ? ClimateRiskTypesTranslations[windRisk.risk]
            : "Nenustatyta"}
        </Typography>
      }
      riskDescription={
        <Typography variant="caption">
          {windRisk?.risk
            ? getWindRiskDescription(windRisk.windyDaysCount)
            : "Vėjo rizika nenustatyta."}
        </Typography>
      }
    />
  );
};
