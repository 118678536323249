import { View } from "react-native";
import { Button } from "../buttons/Button";
import Upload2Icon from "../../assets/icons/upload2.svg";
import DeleteIcon from "../../assets/icons/delete.svg";
import { Typography } from "../Typography";
import React, { useCallback, useRef } from "react";
import colors from "../../styles/colors";
import { maxDocFilesInputCount } from "../../constants/input.constants";
import { IconButton } from "../buttons/IconButton";
import { CheckboxInput } from "../form/CheckboxInput";
import { DocumentUploadHelperDialog } from "./DocumentUploadHelperDialog";
import { FileWrapper } from "../../types/common.types";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

interface InputDocumentSelectionProps {
  hidden?: boolean;
  selectedFiles: FileWrapper[];
  setSelectedFiles: React.Dispatch<React.SetStateAction<FileWrapper[]>>;
}

export const InputDocumentSelection: React.FC<InputDocumentSelectionProps> = ({
  hidden,
  selectedFiles,
  setSelectedFiles,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [openModal, setOpenModal] = React.useState(false);

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setSelectedFiles((prev) =>
        [
          ...prev,
          ...Array.from(e.target.files || []).map((file) => ({
            file,
            isAllowShare: false,
          })),
        ].splice(0, maxDocFilesInputCount)
      );
    }
  };

  const tickFile = useCallback(
    (index: number, newValue: boolean) => {
      setSelectedFiles((prev) =>
        prev.map((wrapper, i) =>
          i === index ? { ...wrapper, isAllowShare: newValue } : wrapper
        )
      );
    },
    [setSelectedFiles]
  );

  const removeFile = useCallback(
    (index: number) => {
      setSelectedFiles((prev) => prev.filter((_, i) => i !== index));
      // allow uploading recently removed file
      if (fileInputRef.current) fileInputRef.current.value = "";
    },
    [setSelectedFiles, fileInputRef]
  );

  return (
    <View
      style={{
        borderColor: colors.greyborder,
        borderWidth: 1,
        display: hidden ? "none" : undefined,
        marginTop: 16,
        padding: 8,
      }}
    >
      <div style={{ display: "flex", justifyContent: "center" }}>
        <WarningAmberIcon color="warning" />
        <Typography style={{ textAlign: "center" }}>
          Šis funkcionalumas yra eksperimentinis ir gali neveikti, keistis
        </Typography>
      </div>
      <Typography style={{ textAlign: "center" }}>
        Įkelkite iki {maxDocFilesInputCount} dokumentų PDF formatu
      </Typography>
      <a
        href="#"
        onClick={() => setOpenModal(true)}
        style={{
          color: colors.purple2,
          textAlign: "center",
          textDecoration: "none",
          fontWeight: "bold",
          marginBottom: 16,
        }}
      >
        Kokius dokumentus įkelti?
      </a>
      <div
        style={{
          /* Button reacts weird to width:80%, thus the div wrapper*/
          width: "80%",
          alignSelf: "center",
          marginBottom: 16,
        }}
      >
        <Button
          backgroundColor="white"
          textStyle={{ color: colors.purple }}
          leftIcon={<Upload2Icon />}
          style={{ borderColor: colors.purple, borderWidth: 1 }}
          onPress={() => fileInputRef.current?.click()}
          disabled={selectedFiles.length >= maxDocFilesInputCount}
        >
          PASIRINKTI DOKUMENTĄ
        </Button>
      </div>
      <input
        id="file"
        type="file"
        hidden={true}
        accept=".pdf,.PDF"
        ref={fileInputRef}
        onChange={onFileChange}
      />
      {selectedFiles.map((wrapper, index) => (
        <div
          key={`${index}-${wrapper.file.name}`}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 16,
            padding: 8,
            border: `1px solid ${colors.greyborder}`,
            borderRadius: 8,
            width: "90%",
            alignSelf: "center",
            textAlign: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography>
              {wrapper.file.name} ___ {(wrapper.file.size / 1024).toFixed(2)} KB
            </Typography>
            <IconButton onPress={() => removeFile(index)}>
              <DeleteIcon />
            </IconButton>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <CheckboxInput
              isChecked={wrapper.isAllowShare}
              onChange={(newValue) => tickFile(index, newValue)}
            />
            <Typography>Dalintis kartu su užklausa</Typography>
          </div>
        </div>
      ))}
      <DocumentUploadHelperDialog
        open={openModal}
        onClose={() => setOpenModal(false)}
      />
    </View>
  );
};
