import React from "react";
import { ContentCard } from "../../components/ContentCard";
import { Grid } from "@mui/material";
import {
  PropertyLocationDetails,
  PropertyLocationDetailsType,
} from "../../types/api.types";
import { PropertyLocationMap } from "./PropertyLocationMap";

interface PropertyLocationContentProps {
  locations: PropertyLocationDetails[];
  onRegenerate?: () => void;
}

const order = [
  PropertyLocationDetailsType.REGIA1,
  PropertyLocationDetailsType.REGIA2,
  PropertyLocationDetailsType.PROTECTED_TERRITORY
];

export const PropertyLocationContent: React.FC<
  PropertyLocationContentProps
> = ({ locations, onRegenerate }) => {
  return (
    <ContentCard
      title="Vietos žemėlapiai"
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "40px",
      }}
    >
      <Grid container spacing={2}>
        {order.map((locationType) => {
          const locationDetails = locations?.find(
            (location) => location.locationType === locationType
          );
          if (!locationDetails) return null;
          return (
            <Grid item xs={12} md={6} key={locationType}>
              <PropertyLocationMap
                locationDetails={locationDetails}
                onRegenerate={onRegenerate}
                printableView={!onRegenerate}
              />
            </Grid>
          );
        })}
      </Grid>
    </ContentCard>
  );
};
