import { CheckboxInput } from "common/components/form/CheckboxInput";
import { SimpleTableComponent } from "../../components/tables/simpleTable/SimpleTableComponent";
import { SimpleTableElement } from "../../components/tables/simpleTable/SimpleTableElement";
import { SimpleTableHeader } from "../../components/tables/simpleTable/SimpleTableHeader";
import { SimpleTableRow } from "../../components/tables/simpleTable/SimpleTableRow";
import { PropertyReadingSingleOverview } from "../../types/api.types";
import { DesignationTypeTranslations } from "../../helpers/comparableFields.helpers";
import { getAddressFromOverview } from "./PropertyViaPDFConfirmRoute";
import { DesignationType } from "common/types/comparableFields.types";

interface PropertyReadingTableProps {
  flattenedProperties: PropertyReadingSingleOverview[];
  onPropertySelect: (index: number, newCheckedValue: boolean) => void;
  onSelectAll: (newCheckedValue: boolean) => void;
}

export const PropertyReadingTable: React.FC<PropertyReadingTableProps> = ({
  flattenedProperties,
  onPropertySelect,
  onSelectAll,
}) => {
  return (
    <SimpleTableComponent>
      <SimpleTableRow>
        <SimpleTableHeader>
          <CheckboxInput
            isChecked={flattenedProperties.every(
              (property) => !!property.isChecked
            )}
            onChange={onSelectAll}
          />
        </SimpleTableHeader>
        <SimpleTableHeader textAlign="left">Turto adresas</SimpleTableHeader>
        <SimpleTableHeader textAlign="left">Turto paskirtis</SimpleTableHeader>
        <SimpleTableHeader textAlign="left">Failų sąrašas</SimpleTableHeader>
      </SimpleTableRow>
      {flattenedProperties.map((propertyOrAsset, index) => (
        <SimpleTableRow key={index}>
          <SimpleTableElement>
            <CheckboxInput
              isChecked={!!propertyOrAsset.isChecked}
              onChange={(newValue) => onPropertySelect(index, newValue)}
            />
          </SimpleTableElement>
          <SimpleTableElement textAlign="left">
            {getAddressFromOverview(propertyOrAsset || "")}
          </SimpleTableElement>
          <SimpleTableElement textAlign="left">
            {DesignationTypeTranslations[
              (propertyOrAsset.data.property?.designationType ||
                propertyOrAsset.data.asset?.designationTypeId ||
                DesignationType.Other) as DesignationType
            ] || "kita"}
          </SimpleTableElement>
          <SimpleTableElement textAlign="left">
            {propertyOrAsset.origins.join("; ")}
          </SimpleTableElement>
        </SimpleTableRow>
      ))}
    </SimpleTableComponent>
  );
};
