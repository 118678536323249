import React from "react";
import { Typography } from "common/components/Typography";
import { IconBadge } from "common/components/map/IconBadge";
import FloodOutlinedIcon from "@mui/icons-material/FloodOutlined";
import colors from "common/styles/colors";
import { PropertyFloodRisk } from "../../types/appraiser.types";
import { ClimateRiskTypesTranslations } from "../../helpers/comparableFields.helpers";
import {
  determineBackgroundColor,
  getFloodRiskDescription,
} from "../../helpers/climateRisk.helpers";
import { PropertyLocationMap } from "./PropertyLocationMap";
import {
  PropertyLocationDetailsType,
  PropertyLocationDetails,
} from "../../types/api.types";
import { PropertyClimateRiskWrapper } from "./PropertyClimateRiskWrapper";

interface PropertyFloodRiskContentProps {
  floodRisk?: PropertyFloodRisk;
  locations: PropertyLocationDetails[];
  onRegenerate?: () => void;
}

export const PropertyFloodRiskContent: React.FC<
  PropertyFloodRiskContentProps
> = ({ floodRisk, locations, onRegenerate }) => {
  const floodLocation = locations.find(
    (location) => location.locationType === PropertyLocationDetailsType.FLOODS
  );

  return (
    <div
      style={{
        marginTop: "25px",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <PropertyClimateRiskWrapper
        title={<Typography variant="h5">Potvynių rizika</Typography>}
        description={
          <Typography variant="caption">
            Vertinama pagal vandens telkinių artumą ir jų galimą poveikį
            teritorijai.
          </Typography>
        }
        icon={
          <IconBadge
            width={40}
            height={40}
            backgroundColor={
              floodRisk?.floodRisk
                ? determineBackgroundColor(floodRisk.floodRisk)
                : colors.grey
            }
          >
            <FloodOutlinedIcon fontSize="medium" style={{ color: "white" }} />
          </IconBadge>
        }
        riskType={
          <Typography variant="h4">
            {floodRisk?.floodRisk
              ? ClimateRiskTypesTranslations[floodRisk.floodRisk]
              : "Nenustatyta"}
          </Typography>
        }
        riskDescription={
          <Typography variant="caption">
            {floodRisk?.floodRisk
              ? getFloodRiskDescription(floodRisk.floodRisk)
              : "Potvynio rizika nenustatyta."}
          </Typography>
        }
      />
      {!!floodLocation && (
        <div style={{ width: "45%" }}>
          <PropertyLocationMap
            locationDetails={floodLocation}
            onRegenerate={onRegenerate}
            printableView={!onRegenerate}
          />
        </div>
      )}
    </div>
  );
};
