import { Typography } from "common/components/Typography";
import {
  FinalPricePredictions,
  PredictedPrice,
} from "common/types/common.types";
import React from "react";
import { AppraiserProperty } from "../../types/appraiser.types";
import { formatPrice } from "common/helpers/text.helpers";
import { getPropertyValueString } from "../../helpers/property.helpers";

interface PredictionOverviewPriceProps {
  predictedPrice: PredictedPrice;
  property: AppraiserProperty;
}

export const PredictionOverviewPrice: React.FC<
  PredictionOverviewPriceProps
> = ({ predictedPrice, property }) => {
  const priceArea =
    predictedPrice.price_area_with_assets ?? predictedPrice.average_price_area;
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      <Typography textStyle={{ fontSize: 20 }}>
        Apskaičiuota {getPropertyValueString(property).toLowerCase()}
      </Typography>
      <Typography variant="h2" textStyle={{ fontSize: 32 }}>
        {formatPrice(priceArea * property.area)}
      </Typography>

      <Typography
        variant="caption"
        textStyle={{ fontSize: 18 }}
      >{`(${formatPrice(priceArea)}/m²)`}</Typography>
    </div>
  );
};
