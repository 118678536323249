import { Button, Chip } from "@mui/material";
import React from "react";
import { CompositeAssetsForm } from "./CompositeAssetsForm";
import { CompositeAssetsTable } from "./CompositeAssetsTable";
import AddIcon from "@mui/icons-material/Add";
import {
  AreaUnit,
  CompositeAssetPreset,
  CompositeAssetRequest,
  CompositePresetType,
} from "../../types/api.types";
import { DesignationType } from "common/types/comparableFields.types";

interface CompositeAssetsProps {
  propertyId: string;
  pricePredictionId?: string;
  isCustomer?: boolean;
  hideValue?: boolean;
}

export const CompositeAssets: React.FC<CompositeAssetsProps> = ({
  propertyId,
  isCustomer,
  pricePredictionId,
  hideValue,
}) => {
  const [open, setOpen] = React.useState(false);
  const [selectedPreset, setSelectedPreset] =
    React.useState<CompositeAssetPreset | null>(null);

  const presets: CompositeAssetPreset[] = [
    {
      name: "Parkingas (antžeminis)",
      designationTypeId: DesignationType.Garage,
      areaUnit: AreaUnit.SqMeters,
      presetType: CompositePresetType.ParkingOverground,
    },
    {
      name: "Parkingas (požeminis)",
      designationTypeId: DesignationType.Garage,
      areaUnit: AreaUnit.SqMeters,
      presetType: CompositePresetType.ParkingUnderground,
    },
    {
      name: "Sandėliukas/Rūsys",
      designationTypeId: DesignationType.OtherSubFarm,
      areaUnit: AreaUnit.SqMeters,
      presetType: CompositePresetType.Storage,
    },
    {
      name: "Žemės sklypas",
      designationTypeId: DesignationType.PlotArea,
      areaUnit: AreaUnit.Hectares,
      presetType: CompositePresetType.PlotArea,
    },
  ];

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          marginBottom: "20px",
          flexWrap: "wrap",
        }}
      >
        {presets.map((preset, index) => (
          <Chip
            key={index}
            icon={<AddIcon />}
            label={preset.name}
            onClick={() => {
              setSelectedPreset(preset);
              setOpen(true);
            }}
            variant="outlined"
          />
        ))}
      </div>

      <CompositeAssetsTable
        propertyId={propertyId}
        isCustomer={isCustomer}
        pricePredicitionId={pricePredictionId}
        hideValue={hideValue}
      />
      <CompositeAssetsForm
        isCustomer={isCustomer}
        open={open}
        onClose={() => {
          setOpen(false);
          setSelectedPreset(null);
        }}
        propertyId={propertyId}
        preset={selectedPreset}
        hideValue={hideValue}
      />
      <Button
        onClick={() => {
          setOpen(true);
        }}
        variant="contained"
        startIcon={<AddIcon />}
        style={{
          alignSelf: "end",
          marginTop: "20px",
        }}
      >
        pridėti priklausomą turtą
      </Button>
    </div>
  );
};
