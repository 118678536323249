import React, { useContext, useEffect, useImperativeHandle } from "react";
import { View, Text } from "react-native";
import { FormLabelField } from "./hookForms/FormLabelField";
import { MyPropertyAddRequest } from "../../types/common.types";
import * as Yup from "yup";
import { getMaxErrorText, getMinErrorText } from "../../helpers/form.helpers";
import { Typography } from "../Typography";
import colors from "../../styles/colors";
import { FormInput } from "./hookForms/FormInput";
import { SmallFormNumberInput } from "./hookForms/SmallFormNumberInput";
import { FormCheckboxInput } from "./hookForms/FormCheckboxInput";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormProvider,
  useForm,
  UseFormHandleSubmit,
  UseFormGetValues,
} from "react-hook-form";
import {
  DesignationType,
  REType,
  getTransactionREType,
} from "../../types/comparableFields.types";
import { FormSelect } from "../../../web-valuations/src/components/form/FormSelect";
import {
  DesignationTypeTranslations,
  WallType,
  WallTypeTranslations,
} from "../../../web-valuations/src/helpers/comparableFields.helpers";
import { FormSliderWithInput } from "../../../web-valuations/src/components/form/FormSliderWithInput";
import { EnergyClassField } from "../../../web-valuations/src/routes/propertiesCRUD/addressInput/EnergyClassField";
import { useRouteQuery } from "../../../web-valuations/src/hooks/router.hooks";
import { PropertyCreationContext } from "../../../web-valuations/src/routes/propertiesCRUD/PropertyCreationProvider";
import { ConstructionYearField } from "../../../web-valuations/src/routes/propertiesCRUD/addressInput/ConstructionYearField";
import dayjs from "dayjs";

interface MyPropertyDetailsEditFormProps {
  initialProperty: Partial<MyPropertyAddRequest>;
  propertyId?: string;
  onCreateNewProperty?: () => void;
  onLocationEdit?: () => void;
  customCityComponent?: React.ReactNode;
  customStreetComponent?: React.ReactNode;
  customStreetNumberComponent?: React.ReactNode;
  customMapComponent?: React.ReactNode;
  designationType?: DesignationType;
  fullFinishing?: boolean;
  showExpectedValue?: boolean;
  soldDateField?: React.ReactNode;
}

export interface MyPropertyDetailsEditFormHandles {
  handleSubmit: UseFormHandleSubmit<MyPropertyAddRequest>;
  getValues: UseFormGetValues<MyPropertyAddRequest>;
}

const minRooms = 1;
const maxRooms = 10;
const minFloor = 0;
const maxFloor = 100;
const minArea = 1;
const maxArea = 1000;
const minBuildYear = 1900;
const maxBuildYear = new Date().getFullYear() + 3;

const areaLabel = "Plotas (m²)";
const plotAreaLabel = "Sklypo plotas (a)";
const roomsLabel = "Kambarių sk.";
const floorLabel = "Aukštas";
const floorCountLabel = "Aukštų sk.";
const buildYearLabel = "Statybos metai";
const streetNumberLabel = "Namo numeris";
const apartmentNumberLabel = "Buto numeris";
const addressLabel = "Adresas";
const finishingLabel = "Baigtumas";

const buildYearSchema = Yup.number()
  .required()
  .min(minBuildYear, getMinErrorText(buildYearLabel, minBuildYear))
  .max(maxBuildYear, getMaxErrorText(buildYearLabel, maxBuildYear));

const isHouse = (designationType: any) =>
  getTransactionREType(designationType) === REType.House;

export const minFinishingFull = 0;
export const maxFinishingFull = 100;

const validationSchema: Yup.ObjectSchema<
  Omit<MyPropertyAddRequest, "condition">
> = Yup.object({
  label: Yup.string().required(),
  lat: Yup.number().required(),
  lng: Yup.number().required(),
  street: Yup.string().required(),
  streetId: Yup.number().required(),
  streetNumber: Yup.string().required(),
  city: Yup.string(),
  cityId: Yup.number(),
  apartmentNumber: Yup.number(),
  address: Yup.string().required(),
  area: Yup.number()
    .required()
    .min(minArea, getMinErrorText(areaLabel, minArea))
    .max(maxArea, getMaxErrorText(areaLabel, maxArea)),
  rooms: Yup.number()
    .required()
    .min(minRooms, getMinErrorText(roomsLabel, minRooms))
    .max(maxRooms, getMaxErrorText(roomsLabel, maxRooms)),
  floorCount: Yup.number()
    .required()
    .min(minFloor, getMinErrorText(floorCountLabel, minFloor))
    .max(maxFloor, getMaxErrorText(floorCountLabel, maxFloor)),
  floorNr: Yup.number().when("designationType", {
    is: (d: any) => !isHouse(d),
    then: (schema) =>
      schema
        .required()
        .min(minFloor, getMinErrorText(floorLabel, minFloor))
        .max(maxFloor, getMaxErrorText(floorLabel, maxFloor)),
    otherwise: (schema) => schema.nullable(),
  }),
  isRenovated: Yup.boolean().required(),
  finishing: Yup.boolean().required(),
  finishingFull: Yup.number().min(minFinishingFull).max(maxFinishingFull),
  renovationYear: Yup.number().when("isRenovated", {
    is: true,
    then: () => buildYearSchema,
  }),
  designationType: Yup.string(),
  energyClass: Yup.string(),
  plotArea: Yup.number().when("designationType", {
    is: isHouse,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.nullable(),
  }),
  year: buildYearSchema,
  walls: Yup.string().oneOf(Object.values(WallType)),
  expectedValue: Yup.number().nullable(),
  soldDate: Yup.string().nullable(),
  uniqueNr: Yup.string(),
});

export const MyPropertyDetailsEditForm = React.forwardRef<
  MyPropertyDetailsEditFormHandles,
  MyPropertyDetailsEditFormProps
>(
  (
    {
      initialProperty,
      propertyId,
      onCreateNewProperty,
      onLocationEdit,
      customCityComponent,
      customStreetComponent,
      customStreetNumberComponent,
      customMapComponent,
      designationType,
      fullFinishing,
      showExpectedValue,
      soldDateField,
    },
    ref
  ) => {
    const gapHeight = 30;

    const getInitialFinishing = () => {
      if (initialProperty.finishing === undefined) {
        return false;
      }
      return !initialProperty.finishing;
    };

    const getInitialFinishingFull = () => {
      if (!fullFinishing) {
        return undefined;
      }

      if (initialProperty.finishingFull !== undefined) {
        return initialProperty.finishingFull;
      }

      return !getInitialFinishing() ? maxFinishingFull : minFinishingFull;
    };

    const cityQuery = useRouteQuery().get("city") ?? undefined;

    const { cityId } = useContext(PropertyCreationContext);

    const methods = useForm<MyPropertyAddRequest>({
      resolver: yupResolver(validationSchema),
      defaultValues: {
        city: cityQuery,
        // @ts-ignore
        cityId,
        ...initialProperty,
        soldDate: !!initialProperty?.soldDate
          ? (dayjs(initialProperty.soldDate) as any)
          : undefined,
        finishing: getInitialFinishing(),
        finishingFull: getInitialFinishingFull(),
        isRenovated: !!initialProperty.renovationYear,
        designationType: designationType ?? initialProperty.designationType,
      },
    });

    useImperativeHandle(ref, () => ({
      handleSubmit: methods.handleSubmit,
      getValues: methods.getValues,
    }));

    useEffect(() => {
      if (!initialProperty.lat || !initialProperty.lng) {
        return;
      }
      methods.setValue("lat", initialProperty.lat);
      methods.setValue("lng", initialProperty.lng);
      methods.setValue("address", initialProperty.address);
      methods.setValue("label", initialProperty.address ?? "");
      methods.setValue("city", initialProperty.city);

      if (initialProperty.street !== undefined)
        methods.setValue("street", initialProperty.street);
      else {
        methods.resetField("street");
      }
      if (initialProperty.streetNumber !== undefined)
        methods.setValue("streetNumber", initialProperty.streetNumber ?? 0);
      else {
        methods.resetField("streetNumber");
      }

      methods.resetField("apartmentNumber");
    }, [initialProperty.lat, initialProperty.lng]);

    const streetNumber = methods.watch("streetNumber");
    const street = methods.watch("street");
    const city = methods.watch("city");

    useEffect(() => {
      if (!customStreetNumberComponent) {
        return;
      }

      if (!streetNumber || !street || !city) {
        methods.setValue("address", "");
        methods.setValue("label", "");
        return;
      }
      function extractCityName(str: string) {
        // Step 1: Remove the parentheses part
        const noParentheses = str.replace(/\(.*\)/, "").trim();

        // Step 2: Split the string by spaces
        const parts = noParentheses.split(" ");

        // Step 3: Filter out parts that end with a period
        const filteredParts = parts.filter((part) => !part.endsWith("."));

        // Step 4: Join the parts back into a string
        return filteredParts.join(" ");
      }

      const address = `${street} ${streetNumber}, ${extractCityName(
        city ?? ""
      )}`;
      methods.setValue("address", address);
      methods.setValue("label", address);
    }, [streetNumber, street, city]);

    return (
      <View>
        <FormProvider {...methods}>
          {customCityComponent ?? (
            <FormLabelField
              name="street"
              label={addressLabel}
              caption={
                propertyId !== undefined ? (
                  <Text style={{ marginBottom: 15 }}>
                    <Typography variant="caption">
                      Jei norite pasirinkti kitą adresą, sukurkite naują turtą.{" "}
                    </Typography>
                    <Typography
                      variant="h4"
                      onPress={onCreateNewProperty}
                      textStyle={{ color: colors.purple2, fontSize: 14 }}
                    >
                      Sukurti naują turtą
                    </Typography>
                  </Text>
                ) : undefined
              }
              onPress={propertyId === undefined ? onLocationEdit : undefined}
              isRequired
            />
          )}
          {!!customStreetComponent ? (
            <>
              <View style={{ height: gapHeight }} />
              {customStreetComponent}
            </>
          ) : null}
          <View style={{ height: gapHeight }} />
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View style={{ flex: 1 }}>
              {customStreetNumberComponent ?? (
                <FormInput
                  label={streetNumberLabel}
                  name="streetNumber"
                  isRequired
                  variant="number-text"
                />
              )}
            </View>
            <View style={{ width: 15 }} />
            <View style={{ flex: 1 }}>
              <FormInput
                label={apartmentNumberLabel}
                name="apartmentNumber"
                variant="number"
              />
            </View>
          </View>
          {!!customMapComponent && (
            <>
              <View style={{ height: gapHeight }} />
              {customMapComponent}
            </>
          )}

          <View style={{ height: gapHeight }} />
          <FormInput
            units="m²"
            label={areaLabel}
            name="area"
            isRequired
            variant="decimal"
          />
          <View style={{ height: gapHeight }} />
          <SmallFormNumberInput
            minValue={minRooms}
            maxValue={maxRooms}
            label={roomsLabel}
            name="rooms"
            isRequired
          />
          <View style={{ height: gapHeight }} />
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            {!isHouse(methods.watch("designationType")) && (
              <>
                <View style={{ flex: 1 }}>
                  <SmallFormNumberInput
                    minValue={minFloor}
                    maxValue={maxFloor}
                    label={floorLabel}
                    name="floorNr"
                    isRequired
                  />
                </View>
                <Typography
                  textStyle={{
                    alignSelf: "flex-start",
                    marginTop: 57,
                    fontSize: 16,
                    marginHorizontal: 14,
                  }}
                >
                  iš
                </Typography>
              </>
            )}
            <View style={{ flex: 1 }}>
              <SmallFormNumberInput
                minValue={minFloor + 1}
                maxValue={maxFloor}
                label={floorCountLabel}
                name="floorCount"
                isRequired
              />
            </View>
          </View>
          {isHouse(methods.watch("designationType")) && (
            <>
              <View style={{ height: gapHeight }} />
              <FormInput
                units="a"
                label={plotAreaLabel}
                name="plotArea"
                isRequired
                variant="decimal"
              />
            </>
          )}
          <View style={{ height: gapHeight }} />
          <ConstructionYearField gap={20} buildYearLabel={buildYearLabel} />
          <View style={{ height: gapHeight }} />
          <FormSelect
            label="Konstruktyvas"
            name="walls"
            values={Object.values(WallType)}
            getTitle={(value) => WallTypeTranslations[value as WallType]}
          />
          <View style={{ height: gapHeight }} />
          <EnergyClassField />
          <View style={{ height: gapHeight }} />
          <View style={{ marginLeft: -10 }}>
            {fullFinishing ? (
              <FormSliderWithInput
                name="finishingFull"
                label={finishingLabel}
                isRequired
                min={minFinishingFull}
                max={maxFinishingFull}
                onChange={(value) => {
                  methods.setValue("finishing", value !== maxFinishingFull);
                }}
              />
            ) : (
              <FormCheckboxInput
                name="finishing"
                label="Dalinė apdaila"
                style={{ fontSize: 18, alignSelf: "center" }}
              />
            )}
          </View>
          <View style={{ height: gapHeight }} />
          <FormInput
            name="uniqueNr"
            variant="text"
            label="Unikalus numeris"
            maxWidth={500}
          />
          {!!showExpectedValue && (
            <>
              <View style={{ height: gapHeight }} />
              <FormInput
                name="expectedValue"
                label="Lūkestinė kaina"
                variant="number"
                maxWidth={500}
                units="€"
              />
            </>
          )}
          {!!soldDateField && (
            <>
              <View style={{ height: gapHeight }} />
              {soldDateField}
            </>
          )}
        </FormProvider>
      </View>
    );
  }
);
