import React from "react";
import { ContentCard } from "../ContentCard";
import { PredictionOverview } from "./PredictionOverview";
import { PropertyCreditsOverview } from "../map/PropertyCreditsOverview";
import { getPropertyValueString } from "../../helpers/property.helpers";
import { AppraiserProperty } from "../../types/appraiser.types";
import { useFinalPricePredictions } from "../../hooks/pricePredictions.hooks";
import { ConfidenceTooltip } from "./ConfidenceTooltip";

interface PredictionOverviewCardProps {
  property: AppraiserProperty;
  canEdit: boolean;
}

export const PredictionOverviewCard: React.FC<PredictionOverviewCardProps> = ({
  property,
  canEdit,
}) => {
  const { data, isLoading } = useFinalPricePredictions(property._id);

  if (isLoading || !data) {
    return null;
  }

  return (
    <ContentCard
      title={getPropertyValueString(property)}
      icon={
        data.confidence && <ConfidenceTooltip confidence={data.confidence} />
      }
    >
      <PredictionOverview
        property={property}
        hasEditRights={canEdit}
        data={data}
      />

      <div style={{ height: "15px" }} />
      <PropertyCreditsOverview style={{ marginTop: "15px" }} withHeader />
    </ContentCard>
  );
};
