import { IconButton } from "common/components/buttons/IconButton";
import React, { useContext, useEffect, useState } from "react";
import FilterIcon from "../../assets/icons/filter.svg";
import { Typography } from "common/components/Typography";
import { Box, Button, Dialog } from "@mui/material";
import { Divider } from "common/components/listItems/Divider";
import { RangeInputField } from "common/components/form/RangeInputField";
import { Comparable } from "common/types/common.types";
import {
  comparableFilterTypeToLabel,
  defaultFilters,
  extendedFilters,
  getDesignationTypeFilterFunction,
  getEnergyClassFilterFunction,
  getFilterFunctionByType,
  getFinishingFilterFunction,
  getFloorFilterFunction,
  getMatchingTransactionFilterFunction,
  getMonthRangeFilter,
  getValuationZonesTypeFilterFunction,
  getWallsTypeFilterFunction,
  otherFilters,
} from "../../helpers/comparableFilters.helpers";
import colors from "common/styles/colors";
import {
  ComparableFilter as ComparableFilterT,
  ComparableFilterType,
} from "../../helpers/comparableFilters.helpers";
import {
  RadioButtonGroup,
  SelectType,
} from "common/components/form/RadioButtonGroup";
import { MonthRangePicker } from "../datepicker/MonthRangePicker";
import { Badge } from "common/components/badge/Badge";
import { CloseIcon } from "common/components/icons/CloseIcon";
import { OutlinedButton } from "common/components/buttons/OutlinedButton";
import { SavedComparableFilter } from "../../types/api.types";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { FilterFieldWrapper } from "../wrappers/FilterFieldWrapper";
import { CheckboxInput } from "common/components/form/CheckboxInput";
import { getFinishingLabel } from "../transactions/helpers";
import {
  DesignationTypesForValuation,
  DesignationTypeTranslations,
  FloorType,
  WallType,
  WallTypeTranslations,
} from "../../helpers/comparableFields.helpers";
import { DesignationType } from "common/types/comparableFields.types";
import { MultiSelect } from "../form/MultiSelect";
import { ConfirmFiltersButtons } from "./ConfirmFiltersButtons";
import { useComparablesData } from "../../hooks/property.hooks";
import { ComparablesFiltersContext } from "../ComparablesFilterProvider";
import { ValuationZoneFilter } from "./ValuationZoneFilter";
import { TransactionsRCContext } from "../TransactionsRCProvider";
import { ComparablesCountOverview } from "./ComparablesCountOverview";
import { AppraiserProperty, AppraiserRole } from "../../types/appraiser.types";
import { SliderWithInput } from "../form/SliderWithInput";
import {
  maxFinishingFull,
  minFinishingFull,
} from "common/components/form/MyPropertyDetailsEditForm";
import { energyClassOptions } from "../../routes/propertiesCRUD/addressInput/EnergyClassField";
import {
  CollapseContainer,
  CollapseContainerRef,
} from "../containers/CollapseContainer";
import { TransactionsImport } from "../../routes/TransactionsImport";
import { useAppraiser } from "../../hooks/auth.hooks";

interface ComparableFilterProps {
  appliedMapFilters: ComparableFilterT[];
  onFiltersSet: (filters: ComparableFilterT[]) => void;
  badgeCount?: number;
  property: AppraiserProperty;
  isInitialFiltersLoad?: boolean;
}

const floorSelectValues = [
  {
    label: "Visi",
    value: FloorType.All,
  },
  {
    label: "Cokolinis aukštas",
    value: FloorType.Basement,
  },
  {
    label: "Pirmas aukštas",
    value: FloorType.First,
  },
  {
    label: "Tarpinis aukštas",
    value: FloorType.Middle,
  },
  {
    label: "Paskutinis aukštas",
    value: FloorType.Last,
  },
];

const wallSelectValues = Object.values(WallType).map((wallType) => {
  return {
    label: WallTypeTranslations[wallType],
    value: wallType,
  };
});

const designationSelectValues = DesignationTypesForValuation.map(
  (designationType) => {
    return {
      label: DesignationTypeTranslations[designationType],
      value: designationType,
    };
  }
);

export const ComparableFilter: React.FC<ComparableFilterProps> = React.memo(
  ({
    appliedMapFilters,
    onFiltersSet,
    badgeCount,
    isInitialFiltersLoad,
    property,
  }) => {
    const [open, setOpen] = useState(false);

    const {
      data: comparablesBase,
      dataFull: comparablesFull,
      dataCombined: comparables,
    } = useComparablesData();

    const {
      addFilter,
      getFilteredComparables,
      setAppliedFilters,
      getAppliedFilter,
      appliedFilters,
      removeFilter,
      setInitialFilters,
    } = useContext(ComparablesFiltersContext);

    const { transactionsFilteringData } = useContext(TransactionsRCContext);

    function setRangeFilter(
      filterType: ComparableFilterType,
      values: (number | undefined)[] | undefined
    ) {
      if (values?.[0] === undefined && values?.[1] === undefined) {
        removeFilter?.(filterType);
        return;
      }

      addFilter?.({
        type: filterType,
        filterFunction: getFilterFunctionByType(filterType, values) as (
          comp: Comparable
        ) => boolean,
        values,
      });
    }

    useEffect(() => {
      if (!isInitialFiltersLoad) {
        return;
      }
      setInitialFilters?.(property);
      setOpen(true);
    }, [isInitialFiltersLoad]);

    const filteredComparablesCount = getFilteredComparables?.(
      comparables,
      otherFilters
    ).length;

    const onClose = () => {
      if (isInitialFiltersLoad) {
        onFiltersSet(appliedMapFilters);
      }
      setOpen(false);
    };

    const fullFiltersRef = React.useRef<CollapseContainerRef>(null);

    const [newlyImportedTransactions, setNewlyImportedTransactions] =
      useState(false);

    const { data: appraiser } = useAppraiser();

    return (
      <>
        <IconButton
          onPress={() => {
            setAppliedFilters?.(appliedMapFilters);
            setOpen(true);
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              margin: 20,
            }}
          >
            <FilterIcon />
            <Typography textStyle={{ fontSize: 12, marginTop: 5 }}>
              Filtruoti
            </Typography>
            {!!badgeCount && (
              <div style={{ position: "absolute", top: -15, right: -8 }}>
                <Badge count={badgeCount} fontSize={10} />
              </div>
            )}
          </div>
        </IconButton>
        <Dialog open={open} onClose={onClose} maxWidth={"md"} scroll="body">
          <div style={{ padding: 30 }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h2">
                Nekilnojamo turto sandorių filtras
              </Typography>
              <div style={{ marginTop: "-10px", marginRight: "-10px" }}>
                <IconButton onPress={onClose}>
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
            <Divider style={{ marginVertical: 20 }} />
            <Typography variant="h3">Nekilnojamo turto apibūdinimas</Typography>
            <div style={{ display: "flex", gap: "40px", marginTop: "20px" }}>
              <Box
                sx={{
                  display: "flex",
                  gap: "25px",

                  flexDirection: "column",
                  flex: 1,
                }}
              >
                <RangeInputField
                  label={comparableFilterTypeToLabel[ComparableFilterType.Area]}
                  onChange={(values) => {
                    setRangeFilter(ComparableFilterType.Area, values);
                  }}
                  values={getAppliedFilter?.(ComparableFilterType.Area)?.values}
                />
                <RangeInputField
                  label={comparableFilterTypeToLabel[ComparableFilterType.Year]}
                  onChange={(values) => {
                    setRangeFilter(ComparableFilterType.Year, values);
                  }}
                  values={getAppliedFilter?.(ComparableFilterType.Year)?.values}
                />
              </Box>
              <Box>
                <ValuationZoneFilter
                  onSelect={(values) => {
                    if (!values.length) {
                      removeFilter?.(ComparableFilterType.ValuationZone);
                      return;
                    }
                    addFilter?.({
                      type: ComparableFilterType.ValuationZone,
                      values,
                      filterFunction:
                        getValuationZonesTypeFilterFunction(values),
                    });
                  }}
                />

                <FilterFieldWrapper
                  label={
                    comparableFilterTypeToLabel[
                      ComparableFilterType.Designation
                    ] ?? ""
                  }
                >
                  <MultiSelect
                    values={designationSelectValues}
                    selectedValues={
                      getAppliedFilter?.(ComparableFilterType.Designation)
                        ?.values
                    }
                    onSelect={(values) => {
                      if (!values.length) {
                        removeFilter?.(ComparableFilterType.Designation);
                        return;
                      }
                      addFilter?.({
                        type: ComparableFilterType.Designation,
                        values,
                        filterFunction:
                          getDesignationTypeFilterFunction(values),
                      });
                    }}
                  />
                </FilterFieldWrapper>
              </Box>
            </div>
            <Box
              sx={{
                paddingBottom: "20px",
                marginBottom: "15px",
                maxWidth: "385px",
                marginTop: "30px",
              }}
            >
              <div style={{ marginBottom: "20px" }}>
                <Typography variant="h3">
                  {comparableFilterTypeToLabel[ComparableFilterType.Dates]}
                </Typography>
              </div>
              <MonthRangePicker
                selectedDatesInFilter={
                  getAppliedFilter?.(ComparableFilterType.Dates)?.values
                }
                onDatesSelect={(start, end) => {
                  addFilter?.(getMonthRangeFilter(start, end));
                }}
              />
            </Box>
            <div>
              <Typography variant="h3">Sandorių atranka</Typography>
              <div style={{ marginLeft: "-10px" }}>
                <CheckboxInput
                  isChecked={
                    !!getAppliedFilter?.(
                      ComparableFilterType.MatchedTransactions
                    )?.values
                  }
                  onChange={(value) => {
                    if (value) {
                      addFilter?.({
                        type: ComparableFilterType.MatchedTransactions,
                        values: value,
                        filterFunction:
                          getMatchingTransactionFilterFunction(value),
                      });
                    } else {
                      removeFilter?.(ComparableFilterType.MatchedTransactions);
                    }
                  }}
                  label="Rodyti tik identifikuotus sandorius"
                  style={{ alignSelf: "center", fontSize: 16 }}
                />
              </div>
            </div>
            <CollapseContainer
              label="Išplėstiniai filtrai taikomi tik įsigytiems sandoriams."
              buttonLabel="RODYTI IŠPLĖSTINIUS FILTRUS"
              ref={fullFiltersRef}
            >
              <div
                style={{ display: "flex", flexDirection: "row", gap: "40px" }}
              >
                <FilterFieldWrapper
                  label={
                    comparableFilterTypeToLabel[ComparableFilterType.Floor] ??
                    ""
                  }
                >
                  <RadioButtonGroup
                    values={floorSelectValues}
                    selectedValue={
                      getAppliedFilter?.(ComparableFilterType.Floor)?.values
                    }
                    onSelect={(value) => {
                      addFilter?.({
                        type: ComparableFilterType.Floor,
                        values: value,
                        filterFunction: getFloorFilterFunction(value) as (
                          comp: Comparable
                        ) => boolean,
                      });
                    }}
                  />
                </FilterFieldWrapper>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                  }}
                >
                  <FilterFieldWrapper
                    label={
                      comparableFilterTypeToLabel[ComparableFilterType.Walls] ??
                      ""
                    }
                  >
                    <MultiSelect
                      values={wallSelectValues}
                      selectedValues={
                        getAppliedFilter?.(ComparableFilterType.Walls)?.values
                      }
                      onSelect={(values) => {
                        if (!values.length) {
                          removeFilter?.(ComparableFilterType.Walls);
                          return;
                        }
                        addFilter?.({
                          type: ComparableFilterType.Walls,
                          values,
                          filterFunction: getWallsTypeFilterFunction(
                            values
                          ) as (comp: Comparable) => boolean,
                        });
                      }}
                    />
                  </FilterFieldWrapper>
                  <FilterFieldWrapper label="Energinė klasė">
                    <MultiSelect
                      values={energyClassOptions.map((val) => ({
                        label: val,
                        value: val,
                      }))}
                      selectedValues={
                        getAppliedFilter?.(ComparableFilterType.EnergyClass)
                          ?.values
                      }
                      onSelect={(values) => {
                        if (!values.length) {
                          removeFilter?.(ComparableFilterType.EnergyClass);
                          return;
                        }
                        addFilter?.({
                          type: ComparableFilterType.EnergyClass,
                          values,
                          filterFunction: getEnergyClassFilterFunction(
                            values as string[]
                          ) as (comp: Comparable) => boolean,
                        });
                      }}
                    />
                  </FilterFieldWrapper>
                  <FilterFieldWrapper label="Baigtumas">
                    <div style={{ height: "10px" }} />
                    <SliderWithInput
                      value={
                        getAppliedFilter?.(ComparableFilterType.Finishing)
                          ?.values
                      }
                      onChange={(values) => {
                        addFilter?.({
                          type: ComparableFilterType.Finishing,
                          values,
                          filterFunction: getFinishingFilterFunction(
                            values as number[]
                          ),
                        });
                      }}
                      min={minFinishingFull}
                      max={maxFinishingFull}
                    />
                  </FilterFieldWrapper>
                </div>
              </div>
            </CollapseContainer>
            {appraiser?.role === AppraiserRole.ADMIN && (
              <CollapseContainer
                label="Importuoti sandorius"
                buttonLabel="IŠPLĖSTI"
              >
                <TransactionsImport
                  onSuccess={() => {
                    setNewlyImportedTransactions(true);
                  }}
                />
              </CollapseContainer>
            )}
            {!!transactionsFilteringData && (
              <div style={{ marginBottom: "30px" }}>
                <ComparablesCountOverview
                  baseTransactionsCount={comparablesBase.length}
                  fullTransactionsCount={comparablesFull.length}
                  otherTransactionsCount={
                    transactionsFilteringData.failedGeocodingCount +
                    transactionsFilteringData.multipleObjectsCount
                  }
                />
              </div>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                gap: "12px",
              }}
            >
              <ConfirmFiltersButtons
                onRefetch={() => {
                  for (const extendedFilter of extendedFilters) {
                    const filter = defaultFilters.find(
                      (d) => d.type === extendedFilter
                    );
                    if (!!filter) {
                      addFilter?.(filter);
                    } else {
                      removeFilter?.(extendedFilter);
                    }
                  }
                  fullFiltersRef.current?.setShowFull(false);
                }}
                onConfirm={(wasRefetched) => {
                  onFiltersSet(appliedFilters ?? []);
                  setNewlyImportedTransactions(false);
                  if (!wasRefetched) {
                    setOpen(false);
                  }
                }}
                filteredComparablesCount={filteredComparablesCount}
                appliedMapFilters={appliedMapFilters}
                property={property}
                refetchNeeded={newlyImportedTransactions}
              />
            </div>
          </div>
        </Dialog>
      </>
    );
  }
);
