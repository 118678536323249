import React, { useEffect } from "react";
import { SimpleDialog } from "../dialogs/SimpleDialog";
import { FormProvider, useForm } from "react-hook-form";
import {
  AreaUnit,
  CompositeAsset,
  CompositeAssetPreset,
  CompositeAssetRequest,
} from "../../types/api.types";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormSelect } from "../form/FormSelect";
import { DesignationType } from "common/types/comparableFields.types";
import { DesignationTypeTranslations } from "../../helpers/comparableFields.helpers";
import { FormInput } from "common/components/form/hookForms/FormInput";
import { createCompositeAsset, updateCompositeAsset } from "../../api/api";
import { showToastError } from "common/toast/toast";
import { getErrorMessage } from "common/helpers/error.helpers";
import { useMutation, useQueryClient } from "react-query";
import { FormSettingsContext } from "common/components/form/hookForms/FormSettingsContext";
import { FormColumns } from "common/components/form/layout/FormColumns";
import { FormFractionInput } from "common/components/form/hookForms/FormFractionInput";
import colors from "common/styles/colors";
import { round } from "common/helpers/common.helpers";
import { ParkingPriceInfo } from "./ParkingPriceInfo";
import { StoragePriceInfo } from "./StoragePriceInfo";

interface CompositeAssetsFormProps {
  open: boolean;
  onClose: () => void;
  propertyId: string;
  compositeAsset?: CompositeAsset;
  isCustomer?: boolean;
  hideValue?: boolean;
  preset?: CompositeAssetPreset | null;
}

const createValidationSchema = (isValueRequired: boolean) => {
  const schema: Yup.ObjectSchema<CompositeAssetRequest> = Yup.object({
    propertyId: Yup.string().required(),
    name: Yup.string().required(),
    designationTypeId: Yup.string().required(),
    uniqueNr: Yup.string(),
    areaTotal: Yup.number().required(),
    areaUnit: Yup.string().oneOf(Object.values(AreaUnit)).required(),
    assetPart1: Yup.number().required(),
    assetPart2: Yup.number().required(),
    isCustomer: Yup.boolean().required(),
    value: isValueRequired
      ? Yup.number().when("isCustomer", {
          is: true,
          then: (schema) => schema.notRequired(),
          otherwise: (schema) => schema.required(),
        })
      : Yup.number(),
    address: Yup.string(),
  }).test(
    "value-check",
    "Value should not exist if isCustomer is true",
    function (value) {
      if (value.isCustomer && value.value !== undefined) {
        return false; // invalid if isCustomer is true and value exists
      }
      return true;
    }
  );
  return schema;
};

export const CompositeAssetsForm: React.FC<CompositeAssetsFormProps> = ({
  open,
  onClose,
  propertyId,
  compositeAsset,
  isCustomer,
  hideValue,
  preset,
}) => {
  const defaultValues = {
    propertyId,
    areaUnit: AreaUnit.SqMeters,
    isCustomer: isCustomer ?? false,
    assetPart1: 1,
    assetPart2: 1,
    ...preset,
  };

  const methods = useForm<CompositeAssetRequest>({
    resolver: yupResolver(createValidationSchema(!hideValue)),
    defaultValues,
  });

  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(
    compositeAsset
      ? (data: CompositeAssetRequest) =>
          updateCompositeAsset(compositeAsset._id, data)
      : createCompositeAsset,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("compositeAssets");
        queryClient.invalidateQueries("report_price");
        onClose();
      },
      onError: (error) => {
        showToastError(getErrorMessage(error));
      },
    }
  );

  useEffect(() => {
    if (open) {
      if (compositeAsset) {
        methods.reset({ ...compositeAsset, isCustomer: isCustomer ?? false });
      } else {
        methods.reset(defaultValues);
      }
    }
  }, [open, compositeAsset]);

  const assetPart1 = methods.watch("assetPart1");
  const assetPart2 = methods.watch("assetPart2");
  const areaTotal = methods.watch("areaTotal");
  const areaUnit = methods.watch("areaUnit");

  const desinationTypeId = methods.watch("designationTypeId");

  const areaMessage = `Vertinamas plotas: ${round(
    (areaTotal / assetPart2) * assetPart1,
    2
  )} ${areaUnit}`;

  return (
    <SimpleDialog
      title={`${!compositeAsset ? "Pridėti" : "Koreguoti"} papildomą turtą`}
      open={open}
      onClose={onClose}
      onSubmit={methods.handleSubmit((data) => {
        mutate(data);
      })}
      isLoading={isLoading}
    >
      <FormProvider {...methods}>
        <FormSettingsContext.Provider
          value={{ smallFont: true, maxInputWidth: 350, inputHeight: 56 }}
        >
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 20,
            }}
          >
            <FormColumns>
              <FormInput
                name="name"
                label="Turto pavadinimas"
                variant="text"
                isRequired
              />

              <FormSelect
                values={Object.values(DesignationType).map((value) => value)}
                getTitle={(value) =>
                  DesignationTypeTranslations[value as DesignationType]
                }
                name="designationTypeId"
                label="Turto paskirtis"
                isRequired
                disabled={compositeAsset?.isPlotArea}
              />
            </FormColumns>
            <FormColumns>
              <FormInput name="uniqueNr" label="Unikalus Nr." variant="text" />
              <FormInput name="address" label="Adresas" variant="text" />
            </FormColumns>
            <FormColumns>
              <div
                style={{ display: "flex", flexDirection: "row", gap: "15px" }}
              >
                <div
                  style={{
                    width: "150px",
                  }}
                >
                  <FormInput
                    name="areaTotal"
                    label="Bendras plotas"
                    variant="decimal"
                    isRequired
                    disabled={compositeAsset?.isPlotArea}
                  />
                </div>
                <div
                  style={{
                    width: "80px",
                  }}
                >
                  <FormSelect
                    name="areaUnit"
                    label="Vienetai"
                    values={Object.values(AreaUnit).map((value) => value)}
                    disabled={compositeAsset?.isPlotArea}
                  />
                </div>
              </div>
              <div>
                <FormFractionInput
                  name1="assetPart1"
                  name2="assetPart2"
                  label="Vertinama dalis"
                  isRequired
                  disabled={compositeAsset?.isPlotArea}
                />
                <div
                  style={{
                    color: colors.grey,
                    fontSize: "15px",
                    marginTop: "5px",
                  }}
                >
                  {areaMessage}
                </div>
              </div>
            </FormColumns>
            {!isCustomer && !hideValue && (
              <FormInput
                name="value"
                label="Rinkos kaina"
                units="€"
                variant="number"
                maxWidth={250}
                isRequired
              />
            )}
            {!isCustomer &&
              !hideValue &&
              desinationTypeId === DesignationType.Garage && (
                <ParkingPriceInfo
                  propertyId={propertyId}
                  selectedType={preset?.presetType}
                />
              )}

            {!isCustomer &&
              !hideValue &&
              desinationTypeId === DesignationType.OtherSubFarm && (
                <StoragePriceInfo propertyId={propertyId} />
              )}
          </form>
        </FormSettingsContext.Provider>
      </FormProvider>
    </SimpleDialog>
  );
};
